import React,{useState,useEffect} from "react";
import axios from "axios";
import config from "../../config";
import NewInput from "../../UI/NewInput/NewInput";
import useInput from "../../hooks/useInput";
import useValidation from "../../hooks/useValidation";
import Modal from 'react-modal';
import EyeImage from '../../assets/images/EyeImage.png';
import ClosedEye from '../../assets/images/ClosedEye.png';
import LoginImage from '../../assets/images/LoginImage.png';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserData} from "../../Features/User/UserSlice";
import Checkbox from "../../UI/Checkbox/Checkbox";
import classes from './LoginModal.module.css';




function LoginModal(props) {

    const {isEmail, isPassword}=useValidation();
    const [loginError, setLoginError] = useState(null);
    const [checkedOne, setCheckedOne] =useState(false);
    const navigate=useNavigate();
    const dispatch=useDispatch();


    const handleChangeOne = (e) => {
        setCheckedOne(!checkedOne);
        console.log(e.target.checked)
    };

    const customStyles = {
        content: {
            // padding: '32px 48px',
            maxWidth: '1548px',
            overflowY: 'hidden',
            padding:'0',
            width: '100%',
            display:'flex',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:'10000'
        },
    };


    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
        isTouched:emailIsTouched,
    } = useInput(isEmail);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
        showPassFalse:showPassFalse,
        isTouched: passwordIsTouched
    } = useInput(isPassword);




    useEffect(() => {

        if(emailIsTouched || passwordIsTouched){
            setLoginError("")
        }

    },[emailIsTouched, passwordIsTouched]);

    let emailMessage =null;
    if(!email){
        emailMessage = "Email is required";
    }
    else if (!emailIsValid){
        emailMessage = "Invalid email";
    }


    let formIsValid = false;
    if (emailIsValid
        && passwordIsValid
    ) {
        formIsValid = true;
    }

    const values = {
        email: email,
        password: password
    }

    let postLogin = async (values) => {
        let formData = new FormData();
        formData = {
            email: values.email,
            password: values.password,
        }
        try {
            let response = await axios.post(`${config.baseUrl}api/login`, formData);
            console.log(response.data, "response login");
            if(checkedOne){
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', response.data.user.role);
                props.setAccessToken(localStorage.getItem('token'));
                // props.setRole(localStorage.getItem('role'));
                dispatch(setUserData(response.data))
            }
            else{
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('role', response.data.user.role);
                props.setAccessToken(sessionStorage.getItem('token'));
                // props.setRole(sessionStorage.getItem('role'));
                dispatch(setUserData(response.data.user));
            }
            if(response.data.token){
                await closeAndResetLoginModal();
            }
        } catch (error) {
            console.log(error, "error message")
            setLoginError("Something went wrong");
        }
    }
    const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        await postLogin(values);
        await resetEmail();
        await resetPassword();
        await setCheckedOne(false);
    }


    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }

    function closeAndResetLoginModal () {
        props.closeLoginModal();
        navigate('/my-profile');
        resetEmail();
        resetPassword();
        showPassFalse();
        setCheckedOne(false);
        setLoginError("");
    }

    function closeAndResetLoginModalWithoutMoving () {
        props.closeLoginModal();
        resetEmail();
        resetPassword();
        showPassFalse();
        setCheckedOne(false);
        setLoginError("");
    }

    function openSignUpCloseLogin (){
        props.closeLoginModal();
        props.openSignUpModal();
    }

    function openSignUpOwnerCloseLogin (){
        props.closeLoginModal();
        props.openSignUpOwnerModal();
    }


    function openForgetPasswordCloseLogin (){
        props.closeLoginModal();
        props.openForgetPasswordModal();
    }

    // if (isLoading) return <Loader/>;

    return (
            <Modal
                isOpen={props.loginModalIsOpen}
                onRequestClose={closeAndResetLoginModalWithoutMoving}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className={classes.modalWhole}>
                    <div>
                        <img className={classes.loginImg} src={LoginImage}/>
                    </div>
                    <div className={classes.loginInformation}>
                        <div className={classes.titleLogin}>Welcome  To Parkin Pals </div>
                        <div className={classes.subtitleLogin}>Welcome back! Please enter your detalis. </div>
                        <form onSubmit={submitHandler}>
                            <NewInput
                                loginError={loginError}
                                hasError = {emailHasError}
                                errorText={emailMessage}
                                input={{
                                    value: email,
                                    placeholder: "Your Email",
                                    type: "email",
                                    onChange: emailChangeHandler,
                                    onBlur: emailBlurHandler
                                }}
                            />
                            <NewInput
                                loginError={loginError}
                                hasError={passwordHasError}
                                errorText="Please enter password. (Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)"
                                onClick={passwordShowHandler}
                                image= {passwordShow ? EyeImage : ClosedEye}
                                input={{
                                    value: password,
                                    placeholder: "Password",
                                    type: passwordShow ? "text" : "password",
                                    onChange: passwordChangeHandler,
                                    onBlur: passwordBlurHandler,
                                    onKeyPress: handleKeyPress
                                }}
                            />
                            <div className={classes.rememberForget}>
                                    <Checkbox
                                        label="Remember"
                                        value={checkedOne}
                                        onChange={handleChangeOne}
                                    />
                                <div className={classes.forgotPassword}
                                onClick={()=>openForgetPasswordCloseLogin()}>Forgot Password</div>
                            </div>
                            <button className={classes.loginBtn} disabled={!formIsValid} type={"submit"}>Log In</button>
                            {loginError && <div className={classes.loginError}>{loginError}</div>}
                        </form>
                        <div className={classes.loginBelow}>
                            <div className={classes.loginLeft} onClick={()=>openSignUpOwnerCloseLogin()}>Register as a lot owner</div>
                            <div className={classes.loginRight} onClick={()=>openSignUpCloseLogin()}>Don’t have an account?
                                <span className={classes.loginSpan}>Sign up</span> </div>
                        </div>
                    </div>
                </div>
            </Modal>
    )
}


export default LoginModal;