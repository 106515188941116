import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config";
import Modal from 'react-modal';
import EyeImage from '../../assets/images/EyeImage.png';
import ClosedEye from '../../assets/images/ClosedEye.png';
import useValidation from "../../hooks/useValidation";
import useInput from "../../hooks/useInput";
import Input from "../../UI/Input/Input";
import classes from './SignUpOwner.module.css';



function SignUpModal(props){


    const {isName, isEmail, isPassword, isPhoneNumber}=useValidation();
    const [signUpError, setSignUpError] = useState(null);
    const customStyles = {
        content: {
            maxWidth: '1548px',
            overflowY: 'hidden',
            padding:'0',
            width: '100%',
            display:'flex',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:'10000'
        },
    };


    const {
        value:companyName,
        isValid: companyNameIsValid,
        hasError: companyNameHasError,
        valueChangeHandler: companyNameChangeHandler,
        inputBlurHandler: companyNameBlurHandler,
        reset: resetCompanyName,
        isTouched:companyNameIsTouched
    } = useInput(isName);

    const {
        value: firstname,
        isValid: firstnameIsValid,
        hasError: firstnameHasError,
        valueChangeHandler: firstnameChangeHandler,
        inputBlurHandler: firstnameBlurHandler,
        reset: resetFirstname,
        isTouched:firstnameIsTouched
    } = useInput(isName);

    const {
        value: lastname,
        isValid: lastnameIsValid,
        hasError:lastnameHasError,
        valueChangeHandler: lastnameChangeHandler,
        inputBlurHandler: lastnameBlurHandler,
        reset: resetLastname,
        isTouched:lastnameIsTouched
    } = useInput(isName);


    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
        isTouched:emailIsTouched
    } = useInput(isEmail);

    const {
        value: phoneNumber,
        isValid: phoneNumberIsValid,
        hasError: phoneNumberHasError,
        valueChangeHandler: phoneNumberChangeHandler,
        inputBlurHandler: phoneNumberBlurHandler,
        reset: resetPhoneNumber,
        isTouched:phoneNumberIsTouched
    } = useInput(isPhoneNumber);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
        showPassFalse:showPassFalse,
        isTouched: passwordIsTouched
    } = useInput(isPassword);

    const {
        value: confirmPasswordValue,
        isValid: confirmPasswordIsValid,
        valueChangeHandler: confirmPasswordChangeHandler,
        inputBlurHandler: confirmPasswordBlurHandler,
        togglePassword: confirmPasswordShowHandler,
        passwordShown: confirmPasswordShow,
        reset: resetConfirmPassword,
        isTouched: confirmPasswordIsTouched
    } = useInput(isPassword);

    useEffect(() => {
        if(companyNameIsTouched || firstnameIsTouched || lastnameIsTouched || emailIsTouched
            || phoneNumberIsTouched || passwordIsTouched
            || confirmPasswordIsTouched){
            setSignUpError("")
        }
    },[companyNameIsTouched, firstnameIsTouched, lastnameIsTouched,emailIsTouched, phoneNumberIsTouched, passwordIsTouched,confirmPasswordIsTouched]);

    let hasError = false;
    let confirmPasswordMessage=null;
    if(password !== confirmPasswordValue){
        hasError = true;
        confirmPasswordMessage = "Passwords do not match"
    }

    let emailMessage =null;
    if(!email){
        emailMessage = "Email is required";
    }
    else if (!emailIsValid){
        emailMessage = "Invalid email";
    }

    let formIsValid = false;
    if ( companyNameIsValid
        && firstnameIsValid
        && lastnameIsValid
        && emailIsValid
        && phoneNumberIsValid
        && passwordIsValid
        && confirmPasswordIsValid
    ) {
        formIsValid = true;
    }


    const values= {
        company_name: companyName,
        first_name:firstname,
        last_name:lastname,
        email:email,
        phone_number:phoneNumber,
        password:password,
        password_confirmation:confirmPasswordValue,
        role:"owner"
    }

    let postRegistration = async (values)=> {
        console.log(values , "values");
        try {
            let formData = new FormData();
            formData = {
                company_name:values.company_name,
                first_name: values.first_name,
                last_name :  values.last_name,
                email: values.email,
                password: values.password,
                password_confirmation: values.password_confirmation,
                phone_number: values.phone_number,
                role:values.role
            }
            let response = await axios.post(`${config.baseUrl}api/register`, formData);
            console.log(response.data, 'registration owner response data');
            if(response.data.data==="success"){
                localStorage.setItem('email', values.email);
                await props.closeSignUpOwnerModal();
                await props.openVerifyEmailModal();
            }
        }
        catch (e) {
            setSignUpError("Something went wrong");
            console.log(e.response, 'registrationOwnerError');
        }
    }

    const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        await postRegistration(values);
        await resetCompanyName();
        await  resetFirstname();
        await resetLastname();
        await  resetEmail();
        await resetPhoneNumber();
        await  resetPassword();
        await  resetConfirmPassword();
        await showPassFalse()
    };


    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }

    function closeAndResetSignUpOwnerModal (){
        props.closeSignUpOwnerModal();
        resetCompanyName();
        resetFirstname();
        resetLastname();
        resetEmail();
        resetPhoneNumber();
        resetPassword();
        resetConfirmPassword();
        showPassFalse();
        setSignUpError('')
    }

    return(
        <Modal
            isOpen={props.signUpOwnerModalIsOpen}
            onRequestClose={closeAndResetSignUpOwnerModal}
            // onAfterOpen={props.closeLoginModal}
            style={customStyles}
            ariaHideApp={false}>
            <div className={classes.modalSignUp}>
                <div className={classes.signUpTitle}>Lot Owner Registration</div>
                <form onSubmit={submitHandler}>
                    <Input
                        loginError={signUpError}
                        hasError={companyNameHasError}
                        errorText="Please enter Company Name."
                        width ='1026px'
                        input={{
                            value: companyName,
                            placeholder: "Company Name",
                            type: "text",
                            onChange: companyNameChangeHandler,
                            onBlur: companyNameBlurHandler
                        }}
                    />
                    <div className={classes.signUpFormRow}>
                        <div className={classes.marginRight}>
                            <Input
                                loginError={signUpError}
                                hasError={firstnameHasError}
                                errorText="Please enter First Name."
                                width ='505px'
                                input={{
                                    value: firstname,
                                    placeholder: "First Name",
                                    type: "text",
                                    onChange: firstnameChangeHandler,
                                    onBlur: firstnameBlurHandler
                                }}
                            />
                        </div>
                        <Input
                            loginError={signUpError}
                            hasError={lastnameHasError}
                            errorText="Please enter Last Name."
                            width ='505px'
                            input={{
                                value: lastname,
                                placeholder: "Last Name",
                                type: "text",
                                onChange: lastnameChangeHandler,
                                onBlur: lastnameBlurHandler
                            }}
                        />
                    </div>
                    <div className={classes.signUpFormRow}>
                        <div className={classes.marginRight}>
                            <Input
                                loginError={signUpError}
                                hasError={emailHasError}
                                errorText={emailMessage}
                                width ='505px'
                                input={{
                                    value: email,
                                    placeholder: "Your Email Address",
                                    type: "email",
                                    onChange: emailChangeHandler,
                                    onBlur: emailBlurHandler
                                }}
                            />
                        </div>
                        <Input
                            loginError={signUpError}
                            hasError={phoneNumberHasError}
                            errorText="Please enter Phone Number."
                            width ='505px'
                            input={{
                                value: phoneNumber,
                                placeholder: "Phone Number",
                                type: "text",
                                onChange: phoneNumberChangeHandler,
                                onBlur: phoneNumberBlurHandler
                            }}
                        />
                    </div>
                    <div className={classes.signUpFormRow}>
                        <div className={classes.marginRight}>
                            <Input
                                loginError={signUpError}
                                hasError = {passwordHasError}
                                errorText="Please enter password. (Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)"
                                width ='505px'
                                image ={passwordShow ? EyeImage : ClosedEye}
                                onClick={passwordShowHandler}
                                input={{
                                    value: password,
                                    placeholder: "Password",
                                    type:passwordShow ? "text" : "password",
                                    onChange: passwordChangeHandler,
                                    onBlur: passwordBlurHandler,
                                }}
                            />
                        </div>
                        <Input
                            loginError={signUpError}
                            hasError = {hasError && confirmPasswordIsTouched}
                            errorText={confirmPasswordMessage}
                            width ='505px'
                            image ={confirmPasswordShow ? EyeImage : ClosedEye}
                            onClick={confirmPasswordShowHandler}
                            input={{
                                value: confirmPasswordValue,
                                placeholder: "Confirm Password",
                                type: confirmPasswordShow ? "text" : "password",
                                onChange: confirmPasswordChangeHandler,
                                onBlur: confirmPasswordBlurHandler,
                                onKeyPress:handleKeyPress
                            }}
                        />
                    </div>
                    <button className={classes.registerBtn}  disabled={!formIsValid} type={"submit"}>Register</button>
                    {signUpError && <div className={classes.signUpError}>{signUpError}</div>}
                </form>
            </div>
        </Modal>
    )
}


export default SignUpModal;