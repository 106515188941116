import React from "react";
import Solutions2 from '../../../assets/images/partnerSolutions/Solutions2.png';
import classes from './PartnerSolutions1.module.css';



function PartnerSolutions1(){
    return(
        <>
            <div className="container">
                <div className={classes.solutionsPart1Whole}>
                    <div className={classes.solutionsPart1Left}>
                        <div className={classes.solutionsTitle}>Our Overview</div>
                        <div className={classes.solutionsSubTitle}>Make your parking property Parkin’ Pal Ready Today</div>
                        <div className={classes.solutionsText}>At Parkin’ Pals we provide parking solutions for you
                            and your customers. Our online payment system allows you to provide competitive services
                            by allowing for online immediate, scheduled or monthly payments.</div>
                        <div className={classes.solutionsText}>Our online software is cost-effective and requires no
                            upfront capital required to build your own payment system. Contact us to find out how you
                            can begin using our system to manage your parking needs.</div>
                    </div>
                    <div>
                        <img src={Solutions2}/>
                    </div>
                </div>
            </div>
        </>

    )
}


export default PartnerSolutions1;