import React from "react";
import classes from './Checkbox.module.css'


const Checkbox = ({ label, value, onChange }) => {

    return (
        <label>
            <input type="checkbox" checked={value} onChange={onChange} />
            {label}
        </label>
    )
}

export default Checkbox;