import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import Close from '../../assets/images/close.png';
import classes from './AddCarToParkingLotModal.module.css';
import Input from "../../UI/Input/Input";
import useInput from "../../hooks/useInput";
import useValidation from "../../hooks/useValidation";
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import Loader from "../../UI/Loader/Loader";


function AddCarToParkingLotModal(props){

    const {isNotEmpty}=useValidation();
    const [isLoading, setIsLoading] = useState(false);
    const [statesOfProvincesDropdownData, setStatesOfProvincesDropdownData]=useState();
    const [modelListDropdownData, setModelListDropdownData]=useState();

    const customStyles = {
        content: {
            padding: '50px 50px 50px 50px',
            maxWidth: '1118px',
            width: '100%',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            background:'#FFFFFF',
            border: '1px solid #D6D6D6',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        overlay: {zIndex: 1000}
    };

    useEffect(() => {
       getStatesOfProvincesDropDownData();
       getModelsListDropDownData();
    }, []);

    const [values, setValues] = useState({
        model_id: '',
        state_id: ''
    });

    const [errors, setErrors] = useState({
        model_id: null,
        state_id: null,
    });

    const handleChangeSelect = ({target: {name, value}}) => {
            setValues({
                ...values,
                [name]: value,
            })
            setErrors({
                ...errors,
                [name]: null
            });
        }

    let getStatesOfProvincesDropDownData = async () => {
        let token= sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/vehicle/states/list`, {
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setStatesOfProvincesDropdownData(response.data.data)
            console.log(response.data, "vehicle dropdown")
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    let getModelsListDropDownData = async () => {
        let token= sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/vehicle/models/list`, {
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setModelListDropdownData(response.data.data)
            console.log(response.data, "vehicle dropdown")
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


    const {
        value: licensePlate,
        isValid: licensePlateIsValid,
        hasError: licensePlateHasError,
        valueChangeHandler:licensePlateChangeHandler,
        inputBlurHandler: licensePlateBlurHandler,
        reset: resetLicensePlate,
    } = useInput(isNotEmpty);


    const {
        value: nickname,
        isValid: nicknameIsValid,
        hasError: nicknameHasError,
        valueChangeHandler:nicknameChangeHandler,
        inputBlurHandler: nicknameBlurHandler,
        reset: resetNickname,
    } = useInput(isNotEmpty);




    let formIsValid = false;
    if (licensePlateIsValid &&
        values.model_id !=="" &&
        values.state_id !== "" &&
        nicknameIsValid
        ) {
        formIsValid = true;
    }


    const submitHandler =  event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        let formData = new FormData();
        formData.append('model_id', values.model_id );
        formData.append('state_id', values.state_id);
        formData.append('license_plate_number', licensePlate);
        formData.append('nickname', nickname);
        props.onAddVehicle(formData);
        closeAndResetAddCarModal()
    }




    function closeAndResetAddCarModal () {
        props.closeAddCarModal();
        resetLicensePlate();
        resetNickname();
        setValues({
            model_id: '',
            state_id: ''
        })
    }

    if (isLoading) return <Loader/>;
    return(
        <>
            <Modal
                isOpen={props.addCarModalIsOpen}
                onRequestClose={closeAndResetAddCarModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className={classes.modalWhole}>
                    <div>
                        <div className={classes.modalTitle}>Add Vehicle To Parking Lot</div>
                        <form className={classes.addCarForm}
                            onSubmit={submitHandler}
                        >
                            <Input
                                hasError={licensePlateHasError}
                                errorText="Insert license plate"
                                label='License Plate Number'
                                color='#10142E'
                                width ='887px'
                                input={{
                                    value: licensePlate,
                                    placeholder: "",
                                    type: "text",
                                    onChange: licensePlateChangeHandler,
                                    onBlur: licensePlateBlurHandler
                                }}
                            />
                            <div className={classes.inputWholeDiv}>
                                <label className={classes.labelSelect}
                                    // htmlFor="vehicle"
                                >Select state of province</label>
                                <select  className={classes.inputSelect} required
                                         name="state_id"
                                         onChange={handleChangeSelect}
                                >
                                    <option value={""}  selected disabled hidden>Select state of province</option>
                                    {statesOfProvincesDropdownData?.map(option=>(
                                        <option
                                            key={option.id}
                                            value={option.id}
                                        >{option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <Input
                                hasError={nicknameHasError}
                                errorText="Insert nickname."
                                label='Nickname'
                                color='#10142E'
                                width ='887px'
                                input={{
                                    value: nickname,
                                    placeholder: "",
                                    type: "text",
                                    onChange: nicknameChangeHandler,
                                    onBlur: nicknameBlurHandler
                                }}
                            />
                            <div className={classes.inputWholeDiv}>
                                <label className={classes.labelSelect}
                                    // htmlFor="vehicle"
                                >Select model</label>
                                <select  className={classes.inputSelect} required
                                         name="model_id"
                                        onChange={handleChangeSelect}
                                >
                                    <option value={""}  selected disabled hidden>Select model</option>
                                    {modelListDropdownData?.map(option=>(
                                        <option
                                            key={option.id}
                                            value={option.id}
                                        >{option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button className={classes.addCar} disabled={!formIsValid}>Add Car</button>
                        </form>
                    </div>
                    <div className={classes.modalRight} onClick={closeAndResetAddCarModal}>
                        <img src={Close} alt=""/><span className={classes.close}>Close</span>
                    </div>
                </div>
            </Modal>
        </>
    )
}


export default AddCarToParkingLotModal;

