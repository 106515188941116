import React from "react";
import MyMarker from "../MyMarker/MyMarker";
import GoogleMapReact from 'google-map-react';


export default function SimpleMap(props){
    const defaultProps = {
        center: { lat: 39.96118, lng: -82.99879 },
        zoom: 11
    };

    const distanceToMouse = (pt, mp) => {
        if (pt && mp) {
            // return distance between the marker and mouse pointer
            return Math.sqrt(
                (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
            );
        }
    };

    console.log(props.parkings)

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBg4w4s6nm3PAI3FiKlKiQCnn-PBy0dRG0" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                distanceToMouse={distanceToMouse}
            >
                {props.showBlock ==="events"? props.events?.map((item, index
                    ) => {
                    return (
                        <MyMarker key={item.id} lat={item.latitude} lng={item.longitude}
                                  text={item.id} tooltip={item.title}
                                  icon={{
                                      width: '57px',
                                      height:'72px',
                                      url:(require('../../assets/images/profile/EventIcon.png')),
                                      scale: 7,
                                  }}
                        />
                    );
                }) :
                props.parkings?.map((item, index
                ) => {
                    return (
                        <MyMarker key={item.id} lat={item.latitude} lng={item.longitude}
                                  text={item.id} tooltip={item.title}
                                  icon={{
                                      width: '50px',
                                      height:'50px',
                                      url:(require('../../assets/images/profile/ParkingIcon.png')),
                                      scale: 7,
                                  }}
                        />
                    );
                })}

            </GoogleMapReact>
        </div>
    );
}