import React from "react";
import classes from './SubmitButton.module.css';



function SubmitButton (props){

    return(
        <button className={classes.submitButton}
            style={{background: props.backgroundColor, color:props.color, border:props.border}}
            type={props.type || 'button'}
            disabled={props.disabled}
            onClick={props.OnClick}>
            {props.children}
        </button>
    )
}


export default SubmitButton;