import React, {useState} from "react";
import ReactCodeInput from "react-code-input";
import Modal from "react-modal";
import classes from './VerifyEmail.module.css';
import axios from "axios";
import config from "../../config";



function VerifyEmail (props){

    const [verifyEmailError, setVerifyEmailError] = useState(null);
    const [inputValue, setInputValue] =useState("");

    const customStyles = {
        content: {
            maxWidth: '628px',
            overflowY: 'hidden',
            padding:'0',
            width: '100%',
            display:'flex',
            top: '45%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:'10000'
        },
    };

    const handleChange = (e) => {
        setInputValue(e)
    }


    let postVerifyEmail = async () => {
        let email=localStorage.getItem('email');
        let formData = new FormData();
        formData = {
            email,
            token : inputValue,
            type: "verify"
        }
        try {
            let response = await axios.post(`${config.baseUrl}api/verify/pin`, formData);
            console.log(response.data);
            if(response.data.success===true){
                await props.closeVerifyEmailModal();
                await props.openLoginModal();
                await setInputValue("");
                await localStorage.removeItem('email');
            }
        } catch (error) {
            setVerifyEmailError("Something went wrong");
            setInputValue("")
        }
    }


    function closeAndResetVerifyEmailModal () {
        props.closeVerifyEmailModal();
        setVerifyEmailError("");
        setInputValue("")
    }

    return(
        <Modal
            isOpen={props.verifyEmailIsOpen}
            onRequestClose={closeAndResetVerifyEmailModal}
            style={customStyles}
            ariaHideApp={false}>
            <div className={classes.modalVerifyEmail}>
                <div className={classes.verifyTitle}>Verify Email</div>
                <div className={classes.subTitle}>Enter the 6-digit code we are have sent via email.</div>
                <div className={classes.centre}>
                    <ReactCodeInput type='text' fields={6} value={inputValue} onChange={handleChange}/>
                </div>
                        <div className={classes.codeAndSend}>Didn't receive any code?
                            <span className={classes.sendAgain}> Send again</span> </div>
                        <button className={classes.verifyBtn} onClick={postVerifyEmail}
                        >Next</button>
                {verifyEmailError && <div className={classes.verifyError}>{verifyEmailError}</div>}
            </div>
        </Modal>
    )
}


export default VerifyEmail;


