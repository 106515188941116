import React,{useState,useEffect} from "react";
import axios from "axios";
import config from "../../config";
import Modal from 'react-modal';
import NewInput from "../../UI/NewInput/NewInput";
import useInput from "../../hooks/useInput";
import useValidation from "../../hooks/useValidation";
import classes from './ResetPasswordModal.module.css';



function ResetPasswordModal(props){


    const customStyles = {
        content: {
            maxWidth: '697px',
            overflowY: 'hidden',
            padding:'0',
            width: '100%',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:'10000'
        },
    };


    const {isNotEmpty}=useValidation();
    const [resetPasswordError, setResetPasswordError] = useState(null);


    const {
        value: resetCode,
        isValid: resetCodeIsValid,
        hasError: resetCodeHasError,
        valueChangeHandler: resetCodeChangeHandler,
        inputBlurHandler: resetCodeBlurHandler,
        reset: resetResetCode,
        isTouched:resetCodeIsTouched
    } = useInput(isNotEmpty);


    useEffect(() => {
        if(resetCodeIsTouched){
            setResetPasswordError("")
        }
    },[resetCodeIsTouched]);


    let formIsValid = false;
    if (resetCodeIsValid) {
        formIsValid = true;
    }

    function closeAndResetResetPasswordModal (){
        props.closePasswordResetModal();
        resetResetCode();
        setResetPasswordError(" ")
    }

    const postResetPassword = async () => {
        let formData = new FormData();
        formData = {
            email: props.emailValue,
            token: resetCode,
            type: "reset"
        }
        try {
            let response = await axios.post(`${config.baseUrl}api/verify/pin`, formData);
            console.log(response.data);
            if(response.data.success===true){
                props.closePasswordResetModal();
                props.openNewPasswordModal()
            }
        } catch (error) {
            console.log(error, "resetPasswordModalError")
            setResetPasswordError("Something went wrong");
        }
    }

    const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        await postResetPassword();
        await resetResetCode();
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }





    return(
        <Modal
            isOpen={props.passwordResetIsOpen}
            onRequestClose={closeAndResetResetPasswordModal}
            // onAfterOpen={props.closeLoginModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <div  className={classes.modalWhole}>
                <div className={classes.modalTitle}>Password Reset</div>
                <div className={classes.modalSubTitle}>You have requested a password reset.
                    To get a new password please check your Email for the red email from chanty and enter the
                    Password reset code we have sent to your email</div>
                <form onSubmit={submitHandler}>
                    <NewInput
                        loginError={resetPasswordError}
                        hasError = {resetCodeHasError}
                        errorText={"Password reset code is required!"}
                        label ={"Password reset code"}
                        input={{
                            value: resetCode,
                            placeholder: "",
                            type: "text",
                            onChange: resetCodeChangeHandler,
                            onBlur: resetCodeBlurHandler,
                            onKeyPress:handleKeyPress
                        }}
                    />
                    <div className={classes.btnErrorDiv}>
                        <button className={classes.confirmPasswordBtn} disabled={!formIsValid} type={"submit"}>Confirm</button>
                        {resetPasswordError && <div className={classes.resetPasswordError}>{resetPasswordError}</div>}
                    </div>
                </form>
            </div>
        </Modal>
    )
}


export default ResetPasswordModal;
