import React from "react";
import Modal from 'react-modal';
import Close from '../../assets/images/close.png';
import classes from './AddCardModal.module.css';
import Input from "../../UI/Input/Input";
import useInput from "../../hooks/useInput";
import useValidation from "../../hooks/useValidation";




function AddCardModal(props){

    const {isCardNumber, isExpDate, isCVV, isCardHolder}=useValidation();

    const customStyles = {
        content: {
            padding: '50px 50px 50px 50px',
            maxWidth: '1118px',
            width: '100%',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            background:'#FFFFFF',
            border: '1px solid #D6D6D6',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
        overlay: {zIndex: 1000}
    };

    const {
        value: cardNumber,
        isValid: cardNumberIsValid,
        hasError: cardNumberHasError,
        valueChangeHandler:cardNumberChangeHandler,
        inputBlurHandler: cardNumberBlurHandler,
        reset: resetCardNumber,
    } = useInput(isCardNumber);

    const {
        value: expiration,
        isValid: expirationIsValid,
        hasError: expirationHasError,
        valueChangeHandler:expirationChangeHandler,
        inputBlurHandler: expirationBlurHandler,
        reset: resetExpiration,
    } = useInput(isExpDate);

    const {
        value: securityCode,
        isValid: securityCodeIsValid,
        hasError: securityCodeHasError,
        valueChangeHandler:securityCodeChangeHandler,
        inputBlurHandler: securityCodeBlurHandler,
        reset: resetSecurityCode,
    } = useInput(isCVV);

    const {
        value: cardholder,
        isValid: cardholderIsValid,
        hasError: cardholderHasError,
        valueChangeHandler:cardholderChangeHandler,
        inputBlurHandler: cardholderBlurHandler,
        reset: resetCardholder,
    } = useInput(isCardHolder);



    let formIsValid = false;
    if (cardNumberIsValid &&
        expirationIsValid &&
        securityCodeIsValid &&
        cardholderIsValid ) {
        formIsValid = true;
    }

    let expiration_month=expiration.slice(0,2);
    let expiration_year =expiration.slice(3,5);



    const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        let formData = new FormData();
        formData.append('card_number', cardNumber );
        formData.append('expiration_year', expiration_year);
        formData.append('expiration_month',expiration_month);
        formData.append('cvc', securityCode );
        formData.append('name_on_card',cardholder);
        props.onAddCard(formData);
        closeAndResetAddCardModal();
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }



    function closeAndResetAddCardModal () {
        props.closeAddCardModal();
        resetCardNumber();
        resetExpiration();
        resetSecurityCode();
        resetCardholder();
    }

    return(
        <>
            <Modal
                isOpen={props.addCardModalIsOpen}
                onRequestClose={closeAndResetAddCardModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className={classes.modalWhole}>
                    <div>
                        <div className={classes.modalTitle}>Add  Card</div>
                        <form className={classes.addCardForm}
                            onSubmit={submitHandler}
                        >
                            <Input
                                hasError={cardNumberHasError}
                                errorText="Invalid card number."
                                label='Card Number'
                                color='#10142E'
                                width ='887px'
                                input={{
                                    value: cardNumber,
                                    placeholder: "",
                                    type: "number",
                                    onChange: cardNumberChangeHandler,
                                    onBlur: cardNumberBlurHandler
                                }}
                            />
                            <Input
                                hasError={expirationHasError}
                                errorText="Invalid expiry date."
                                label='Expiration (MM/YY)'
                                color='#10142E'
                                width ='887px'
                                input={{
                                    value: expiration,
                                    placeholder: "",
                                    type: "text",
                                    onChange: expirationChangeHandler,
                                    onBlur: expirationBlurHandler
                                }}
                            />
                            <Input
                                hasError={securityCodeHasError}
                                errorText="Invalid security code."
                                label='CVV/CVC Code'
                                color='#10142E'
                                width ='887px'
                                input={{
                                    value: securityCode,
                                    placeholder: "",
                                    type: "number",
                                    onChange: securityCodeChangeHandler,
                                    onBlur: securityCodeBlurHandler
                                }}
                            />
                            <Input
                                hasError={cardholderHasError}
                                errorText="Insert cardholder."
                                label='Cardholder'
                                color='#10142E'
                                width ='887px'
                                input={{
                                    value: cardholder,
                                    placeholder: "",
                                    type: "text",
                                    onChange:cardholderChangeHandler,
                                    onBlur: cardholderBlurHandler,
                                    onKeyPress:handleKeyPress
                                }}
                            />
                            <button className={classes.addCard} disabled={!formIsValid}>Add Card</button>
                        </form>
                    </div>
                    <div className={classes.modalRight} onClick={closeAndResetAddCardModal}>
                        <img src={Close} alt=""/><span className={classes.close}>Close</span>
                    </div>
                </div>
            </Modal>
        </>
    )
}


export default AddCardModal;


