import React from "react";
import classes from './Footer.module.css';
import FooterLogo from '../../assets/images/footer/FooterLogo.png';
import FooterBackground from "../../assets/images/footer/Footerbackground.png";




function Footer(){
    return(
        <div className={classes.footerWhole} style={{ 'background-image': `url(${FooterBackground})`}}>
            <div className="container">
                <div className={classes.footerInside}>
                                   <div className={classes.footerSecondPart}>
                        <div className={classes.footerSecond}>Battery Jumping</div>
                        <div className={classes.footerSecond}>Contact Us</div>
                        <div className={classes.footerSecond}>Partner Solutions</div>
                        <div className={classes.footerSecond}>Pay To Park</div>
                        <div className={classes.footerSecond}>Tire Services</div>
                        <div className={classes.title}>Ⓒ 2022 - All Rights Are Reserved Design By Ui-Ux, LLC</div>
                    </div>
                    <div>
                        <img src={FooterLogo}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;