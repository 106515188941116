import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import moment from "moment";
import config from "../../config";
import pusher from "../../components/Pusher";
import Pusher from 'pusher-js';
import {toast} from "react-toastify";
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import Loader from "../../UI/Loader/Loader";
import {Swiper, SwiperSlide} from 'swiper/react';
import {useDispatch, useSelector} from "react-redux";
import {Keyboard, Pagination, Navigation} from 'swiper';
import NewHeader from "../../components/NewHeader/NewHeader";
import AddCardModal from "../../components/AddCardModal/AddCardModal";
import Illustration from '../../assets/images/reserveParking/Illustration.png';
import {changeZoneParkingsAvailability, setParkingZoneData} from "../../Features/ParkingZone/ParkingZoneSlice";
import AddCarToParkingLotModal from "../../components/AddCarToParkingLotModal/AddCarToParkingLotModal";
import 'swiper/css';
import 'swiper/css/keyboard';
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'react-clock/dist/Clock.css'
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import classes from './ReserveParking.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate, useParams} from "react-router-dom";


function useIsFirstRender() {
    const isFirst = useRef(true)

    if (isFirst.current) {
        isFirst.current = false

        return true
    }

    return isFirst.current
}


function ReserveParking() {

    let first = useIsFirstRender()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {id_param} = useParams();
    const parkingZ = useSelector((state) => state?.parkingZone?.data);
    const [isLoading, setIsLoading] = useState(false);
    const [flagPusher, setFlagPusher] = useState(false);
    const [addCarModalIsOpen, setAddCarModal] = useState(false);
    const [addCardModalIsOpen, setAddCardModal] = useState(false);
    const [vehicleDropdownData, setVehicleDropdownData] = useState([]);
    const [paymentDropdownData, setPaymentDropdownData] = useState([]);
    const [activeSlide, setActiveSlide] = useState(null);
    const [showBelowData, setShowBelowData] = useState(false);
    const [durationInHours, setDurationsInHours] = useState(null);
    const [time, setTime] = useState(null);
    const [valueInCalendar, setValueInCalendar] = useState(new Date());
    const [timeError,setTimeError] =useState(null);
    const [reserved, setReserved] = useState(false);
    let calendarTransformedData = moment(new Date(valueInCalendar)).format("YYYY-MM-DD");
    const [values, setValues] = useState({
        vehicle_id: '',
        card_id: ''
    });


    const [errors, setErrors] = useState({
        vehicle_id: null,
        card_id: null,
    });


    let x = async () => {
        let token = sessionStorage.getItem('token');
        console.log(parkingZ);
        let response = await axios.get(`${config.baseUrl}api/customer/parking/zone?id=${parkingZ?.id}&&start_date=${calendarTransformedData + " " + time}&&hour=${durationInHours}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        setShowBelowData(true);
        setIsLoading(false);
        dispatch(setParkingZoneData(response.data.data));
    }

    useEffect(()=>{
        if(calendarTransformedData && time){
            setTimeError ("")
        }
    },[time]);

    useEffect(() => {
        console.log(first, 'first');
        if(!first){
            x()
        }
    }, [flagPusher])

    console.log(flagPusher, 'flagPusher');

    useEffect(() => {
        let channel = pusher.subscribe(`zone-parking.${parkingZ?.id}`);
        channel.bind('parking', function (data) {
            console.log(data, 565656);
            setFlagPusher({...data})
        });
        return () => {
            // pusher.unsubscribe('channel');
            // channel.unbind_all();
            // channel.unsubscribe();
            // pusher.unsubscribe('channel_name2')
        }
    }, []);





    let getParkingZoneDataInReserve = async (id, startDate, hour) => {
        if (!id) {
            id = id_param
        }
        let token = sessionStorage.getItem('token');
        const queryParams = {};
        if (startDate) {
            queryParams.start_date = calendarTransformedData + " " + time;
        }
        if (hour) {
            queryParams.hour = durationInHours;
        }
        if (!calendarTransformedData || !time) {
            setTimeError ("Please select start time")
        }
        try {
            const queryString = Object.keys(queryParams)
                .map((key) => `${key}=${queryParams[key]}`)
                .join('&');
            let response = await axios.get(`${config.baseUrl}api/customer/parking/zone?id=${id}&&${queryString}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setShowBelowData(true);
            setIsLoading(false);
            dispatch(setParkingZoneData(response.data.data));
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            toast.error(
                error.response?.data.data || "Something went wrong"
            )
        }
    }

    function openAddCardModal() {
        setAddCardModal(true);
    }

    function closeAddCardModal() {
        setAddCardModal(false);
    }

    function openAddCarModal() {
        setAddCarModal(true)
    }

    function closeAddCarModal() {
        setAddCarModal(false);
    }

    useEffect(() => {
        getVehicleDropdownData();
        getPaymentDropdownData();
    }, []);

    let getVehicleDropdownData = async () => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/vehicle`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setVehicleDropdownData(response.data.data);
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    let addVehicle = async (form) => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.post(`${config.baseUrl}api/vehicle/create`, form, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            toast.success(response.data.message);
            let vehicleDropdownDataCopy = [...vehicleDropdownData];
            vehicleDropdownDataCopy.push(response.data.data);
            setVehicleDropdownData(vehicleDropdownDataCopy);
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    let getPaymentDropdownData = async () => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/payment-method`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setPaymentDropdownData(response.data.data)
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )

        }
    }

    let addCard = async (form) => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.post(`${config.baseUrl}api/payment-method/create`, form, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            toast.success(response.data.message);
            let paymentDropdownDataCopy = [...paymentDropdownData];
            paymentDropdownDataCopy.push(response.data.data);
            setPaymentDropdownData(paymentDropdownDataCopy);
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


    let chooseAvailableParking = (count, idOfParking) => {
        if (!parkingZ?.disabled && count === 0) {
            dispatch(changeZoneParkingsAvailability(idOfParking));
            setValues({
                ...values,
                parking_id: idOfParking
            })
        }
    }

    const handleChangeDurationHour = (e) => {
        setDurationsInHours(e.target.value)
    }


    const handleChange = ({target: {name, value}}) => {
        if (value === "add_car") {
            openAddCarModal();
            setValues({
                ...values,
                vehicle_id: "",
            })
        } else if (value === "add_card") {
            openAddCardModal();
            setValues({
                ...values,
                card_id: "",
            })
        } else {
            setValues({
                ...values,
                [name]: value,
            })
            setErrors({
                ...errors,
                [name]: null
            });
        }
    };

    const handleChangeChanges = (targets) => {
        const updatedValues = {...values};
        const updatedErrors = {...errors};
        targets.forEach((target) => {
            const {name, value} = target;
            updatedValues[name] = value;
            updatedErrors[name] = null;
        });
        setValues(updatedValues);
        setErrors(updatedErrors);
    };


    let reserveParking = async () => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        let formData = new FormData();
        formData.append('parking_id', values.parking_id);
        formData.append('start_date', calendarTransformedData + " " + time);
        formData.append('hour', values.hour);
        formData.append('price', values.price);
        formData.append('vehicle_id', values.vehicle_id);
        formData.append('card_id', values.card_id);
        try {
            let response = await axios.post(`${config.baseUrl}api/customer/parking/order/create`, formData, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            if (response.data.data === "Success") {
                navigate('/success-reserved');
                setReserved(true)
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.data.message || "Something went wrong"
            )
        }
    }


    const renderParkings = parkingZ?.parkings?.map((item, index) => (
        <td key={index}>
            <div
                className={`${item?.order_parkings_count === 0 && !item?.parkingAvailabiltyStyle && !parkingZ?.disabled ? classes.grey :
                    // item?.order_parkings_count > 0 && !parkingZ?.disabled? classes.blue: item?.order_parkings_count === 0 && parkingZ?.disabled?
                    item?.order_parkings_count > 0 ? classes.blue : item?.order_parkings_count === 0 && parkingZ?.disabled ?
                        classes.greyCursor : item?.order_parkings_count == 0 && parkingZ?.disabled ? classes.purpleCursor : classes.purple}`}
                onClick={() => chooseAvailableParking(item?.order_parkings_count, item.id)}/>
        </td>
    ))


    const renderParkingHours = parkingZ?.hours?.map((item, index) => (
        <SwiperSlide className={`${activeSlide === item.hour ? classes.durationWholeActive : classes.durationWhole}`}
                     key={index}
                     onClick={() => {
                         setActiveSlide(item.hour);
                         handleChangeChanges([
                             {name: "hour", value: item.hour},
                             {name: "price", value: item.price}
                         ]);
                     }}>
            <div className={classes.durationDetails}>
                <div>
                    {item.hour}
                </div>
                <div style={{display: "flex"}}>{item.price} $</div>
            </div>
        </SwiperSlide>
    ))


    if (isLoading) return <Loader/>;

    return (
        <>
            <NewHeader/>
            <div className={classes.reserveParkingWhole}>
                <div className={classes.reserveLeft}>
                    <div className={classes.reserveUp}>
                        <div>
                            <div className={classes.reserveTitle}>Interactive Parking Map</div>
                            <div className={classes.reserveText}>Lorem ipsum dolor sit amet consectetur. Tristique ac
                                viverra
                                sem lacus neque. Sit erat interdum quam aliquam ut. Ut nec ac mi tempus habitasse vitae
                                nulla
                                quam aliquam. Porta morbi nibh lectus egestas scelerisque ipsum lorem.
                            </div>
                        </div>
                        <div className={classes.imageDiv}>
                            <img className={classes.illustrationImg} src={Illustration} alt=""/>
                        </div>
                    </div>
                    <div className={classes.reserveDown}>
                        <div className={classes.calendarDiv}>
                            <div className={classes.labelSelect}>Choose the date *</div>
                            <Calendar onChange={setValueInCalendar}
                                      value={valueInCalendar}/>
                        </div>
                        <div className={classes.middle}>
                        <div className={classes.hoursDiv}>
                            <label
                                // for="time-input"
                                className={classes.labelSelect}>Time *</label>
                            <TimePicker onChange={setTime}
                                        value={time}
                                        maxDetail={"second"}
                                        format="HH:mm"
                                        minTime="00:00:00"
                                        maxTime={"23:59:00"}
                                        className={`${timeError ? classes.errorStyle : classes.timeStyle}`}
                                        dateFormat="HH:mm"/>
                        </div>
                            {timeError && <div className={classes.error}>{timeError}</div> }
                        <div className={classes.hoursDiv}>
                            <label htmlFor="duration" className={classes.labelSelect}>Duration in hours</label>
                            <input type={"number"} name="duration_hour" value={durationInHours} min="1"
                                   className={classes.inputDuration} onChange={handleChangeDurationHour}/>
                        </div>

                       </div>
                        <button className={classes.parkingBtn}
                                onClick={() => {
                                    setIsLoading(true);
                                    getParkingZoneDataInReserve(parkingZ?.id, (calendarTransformedData + " " + time), durationInHours)
                                }}>
                            See parking details
                        </button>
                    </div>
                    <div className={classes.hoursNewDiv}>
                        {showBelowData && parkingZ?.hours?.length !== 0 && !parkingZ?.disabled ?
                            <>
                                <div className={classes.startTime}>Parking Duration And Prices</div>
                                <Swiper
                                    slidesPerView={7}
                                    spaceBetween={40}
                                    keyboard={{enabled: true}}
                                    modules={[Keyboard, Pagination, Navigation]}
                                    pagination={{
                                        clickable: true
                                    }}
                                    navigation={true}
                                    className={"max_widthSwiperDuration"}
                                >
                                    {renderParkingHours}
                                </Swiper>
                            </>
                            : null
                        }
                    </div>
                    <table>
                        <tbody>
                        <tr>
                            {showBelowData && renderParkings}
                        </tr>
                        </tbody>
                    </table>
                    {parkingZ?.disabled &&
                        <div className={classes.notAllowedText}>No available parking for the period!</div>}
                    {showBelowData && <div className={classes.colorsWholeDiv}>
                        <div className={classes.colorsDiv}>
                            <div className={classes.colorsItemBlue}/>
                            <div className={classes.colorType}>Sold</div>
                        </div>
                        <div className={classes.colorsDiv}>
                            <div className={classes.colorsItemPurple}/>
                            <div className={classes.colorType}>Selected</div>
                        </div>
                        <div className={classes.colorsDiv}>
                            <div className={classes.colorsItemGrey}/>
                            <div className={classes.colorType}>Available</div>
                        </div>
                    </div>
                    }
                </div>
                <div className={classes.reserveRight}>
                    <div className={classes.reserveRightUp}>
                        <div className={classes.inputWholeDiv}>
                            <label className={classes.labelSelect}>Select Vehicle</label>
                            <select className={classes.inputSelect} required
                                    name="vehicle_id" value={values?.vehicle_id} onChange={handleChange}>
                                <option value={""} selected disabled hidden>Select Vehicle</option>
                                <option
                                    value="add_car">Add car
                                </option>
                                {vehicleDropdownData?.map(option => (
                                    <option
                                        key={option.id}
                                        value={option.id}
                                    >{`${option?.vehicle_model.name + " " + option?.license_plate_number + " " + option?.nickname}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={classes.inputWholeDiv}>
                            <label className={classes.labelSelect}>Payment Method</label>
                            <select className={classes.inputSelect} required
                                    name="card_id" value={values?.card_id}
                                    onChange={handleChange}
                            >
                                <option value={""} selected disabled hidden>Select payment method</option>
                                <option
                                    value="add_card"
                                >Add card
                                </option>
                                {paymentDropdownData?.map(option => (
                                    <option
                                        key={option.id}
                                        value={option.id}
                                    >{`${option.type + " " + "**** **** **** " + option.cardNo}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={classes.parkingInformationWhole}>
                        <div className={classes.parkingInformationTitle}>Parking Information</div>
                        <div className={classes.parkingInformation}>{parkingZ?.address}</div>
                        {parkingZ?.name &&
                            <div className={classes.parkingInformation}>Zone <span>{parkingZ?.name}</span></div>}
                        {showBelowData && <div className={classes.parkingInformation}>Total
                            Number:<span> {parkingZ?.parkings?.length}</span></div>}
                        {showBelowData && <div
                            className={classes.parkingInformation}>Busy: <span>{(parkingZ?.parkings?.filter((item) => item.order_parkings_count > 0)).length}</span>
                        </div>}
                        {showBelowData && <div className={classes.parkingInformation}>Free
                            Places: <span>{(parkingZ?.parkings?.filter((item) => item.order_parkings_count === 0)).length}</span>
                        </div>}
                        <div className={classes.totalPriceDiv}>
                            <div className={classes.total}>Total Price</div>
                            <div className={classes.totalPrice}>{values?.price}</div>
                        </div>
                        <div className={classes.reserveBtn} onClick={() => reserveParking()}>Reserve Parking</div>
                    </div>
                </div>
            </div>
            <AddCardModal addCardModalIsOpen={addCardModalIsOpen}
                          closeAddCardModal={closeAddCardModal} onAddCard={addCard}/>
            <AddCarToParkingLotModal addCarModalIsOpen={addCarModalIsOpen}
                                     closeAddCarModal={closeAddCarModal} onAddVehicle={addVehicle}/>
        </>
    )
}


export default ReserveParking;