import React, {useEffect, useState} from "react";
import Input from "../../../UI/Input/Input";
import useValidation from "../../../hooks/useValidation";
import useInput from "../../../hooks/useInput";
import classes from './Vehicle.module.css';
import Close from "../../../assets/images/close.png";
import axios from "axios";
import config from "../../../config";
import {toast} from "react-toastify";
import Loader from "../../../UI/Loader/Loader";




function Vehicles (props){

    const [showAddVehicle, setShowAddVehicle]=useState(false);
    const {isNotEmpty}=useValidation();
    const [isLoading, setIsLoading] = useState(false);
    const [statesOfProvincesDropdownData, setStatesOfProvincesDropdownData]=useState();
    const [modelListDropdownData, setModelListDropdownData]=useState();

    const [values, setValues] = useState({
        model_id: '',
        state_id: ''
    });

    const [errors, setErrors] = useState({
        model_id: null,
        state_id: null,
    });

    const handleChangeSelect = ({target: {name, value}}) => {
        setValues({
            ...values,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]: null
        });
    }


    useEffect(() => {
        getStatesOfProvincesDropDownData();
        getModelsListDropDownData();
    }, [showAddVehicle]);

    let getStatesOfProvincesDropDownData = async () => {
        let token= sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/vehicle/states/list`, {
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setStatesOfProvincesDropdownData(response.data.data)
            console.log(response.data, "vehicle dropdown")
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    let getModelsListDropDownData = async () => {
        let token= sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/vehicle/models/list`, {
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setModelListDropdownData(response.data.data)
            console.log(response.data, "vehicle dropdown")
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


    const {
        value: licensePlate,
        isValid: licensePlateIsValid,
        hasError: licensePlateHasError,
        valueChangeHandler:licensePlateChangeHandler,
        inputBlurHandler: licensePlateBlurHandler,
        reset: resetLicensePlate,
    } = useInput(isNotEmpty);


    const {
        value: nickname,
        isValid: nicknameIsValid,
        hasError: nicknameHasError,
        valueChangeHandler:nicknameChangeHandler,
        inputBlurHandler: nicknameBlurHandler,
        reset: resetNickname,
    } = useInput(isNotEmpty);

    let formIsValid = false;
    if (licensePlateIsValid &&
        values.model_id !=="" &&
        values.state_id !== "" &&
        nicknameIsValid
    ) {
        formIsValid = true;
    }


    const submitHandler =  event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        let formData = new FormData();
        formData.append('model_id', values.model_id );
        formData.append('state_id', values.state_id);
        formData.append('license_plate_number', licensePlate);
        formData.append('nickname', nickname);
        props.onAddVehicle(formData);
        closeAndResetVehicles()
    }



    function closeAndResetVehicles() {
        setShowAddVehicle(!showAddVehicle);
        resetLicensePlate();
        resetNickname();
        setValues({
            model_id: '',
            state_id: ''
        })
    }

    if (isLoading) return <Loader/>;
    return(
        <div className="settingsWhole">
            <div className="settingsTitle">
                {!showAddVehicle ?
                    <div className="titleDetails">Vehicles</div> :
                    <>
                        <div className="titleDetails" onClick={()=>closeAndResetVehicles()}>Add Vehicle</div>
                        <div className="edit" onClick={()=>closeAndResetVehicles()} >
                            <img src={Close} className="editImg" alt=""/>Close</div>
                    </>
                }
            </div>
            {!showAddVehicle && props?.vehicleData?.length ===0?
                <>
                    <div className="notification">You haven’t added a vehicle yet</div>
                    <div className="blueBtn" onClick={()=>setShowAddVehicle(!showAddVehicle)}>+ Add Vehicle</div>
                </> :
                (!showAddVehicle && props?.vehicleData?.length !==0)?
                    <>
                        <div className={classes.vehicleWhole}>
                            {props?.vehicleData?.map(item => (
                                <ul
                                    key={item.id}
                                    className={classes.vehicleDiv}
                                ><li>{`${item?.vehicle_model.name + " " + item?.license_plate_number + " " + item?.nickname}`}</li>
                                </ul>
                            ))}
                        </div>
                        <div className="blueBtn" onClick={()=>setShowAddVehicle(!showAddVehicle)}>+ Add Vehicle</div>
                    </>
                :
                <>
                    <form
                        onSubmit={submitHandler}
                    >
                        <Input
                            hasError={licensePlateHasError}
                            errorText="Insert license plate"
                            label='License Plate Number'
                            color='#10142E'
                            width ='100%'
                            input={{
                                value: licensePlate,
                                placeholder: "",
                                type: "text",
                                onChange: licensePlateChangeHandler,
                                onBlur: licensePlateBlurHandler
                            }}
                        />
                        <div className={classes.inputWholeDiv}>
                            <label className={classes.labelSelect}
                                // htmlFor="vehicle"
                            >Select state of province</label>
                            <select  className={classes.inputSelect} required
                                     name="state_id"
                                     onChange={handleChangeSelect}
                            >
                                <option value={""}  selected disabled hidden>Select state of province</option>
                                {statesOfProvincesDropdownData?.map(option=>(
                                    <option
                                        key={option.id}
                                        value={option.id}
                                    >{option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <Input
                            hasError={nicknameHasError}
                            errorText="Insert nickname."
                            label='Nickname'
                            color='#10142E'
                            width ='100%'
                            input={{
                                value: nickname,
                                placeholder: "",
                                type: "text",
                                onChange: nicknameChangeHandler,
                                onBlur: nicknameBlurHandler
                            }}
                        />
                        <div className={classes.inputWholeDiv}>
                            <label className={classes.labelSelect}
                                // htmlFor="vehicle"
                            >Select model</label>
                            <select  className={classes.inputSelect} required
                                     name="model_id"
                                     onChange={handleChangeSelect}
                            >
                                <option value={""}  selected disabled hidden>Select model</option>
                                {modelListDropdownData?.map(option=>(
                                    <option
                                        key={option.id}
                                        value={option.id}
                                    >{option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button className={classes.submitVehicle} disabled={!formIsValid}>Save Vehicle</button>
                </form>
                </>
            }
        </div>
    )
}


export default Vehicles;