import React from "react";
import Solutions3 from '../../../assets/images/partnerSolutions/Solutions3.png';
import Icon1 from '../../../assets/images/partnerSolutions/Icon1.png';
import Icon2 from '../../../assets/images/partnerSolutions/Icon2.png';
import classes from './PartnerSolutions2.module.css';
import {useNavigate} from "react-router-dom";
import Button from "../../../UI/Button/Button";



function PartnerSolutions2 (props){


    return(
        <>
            <div className={classes.backgroundStyle} style={{ 'background-image': `url(${Solutions3})`}}>
                <div className={classes.solutionsInside}>
                    <div className={classes.inside1}>
                        <img src={Icon1}/>
                        <div className={classes.inside1Text}>Business Growth</div>
                    </div>
                    <div className={classes.inside2}>Parkin’ Pals’ mission is to innovate your business into a luxury
                        parking experience. Most transient parkers put minimal effort into deciding where to park.
                        Through exposure providing high recognition of your parking property, we’ll help make the decision
                        for potential parkers.</div>
                    <div className={classes.inside3}>
                        <img src={Icon2}/>
                        <div className={classes.inside3Text}>Sustainability</div>
                    </div>
                    <div className={classes.inside3}>
                        <img src={Icon2}/>
                        <div className={classes.inside3Text}>Performance</div>
                    </div>
                    <div className={classes.inside3}>
                        <img src={Icon2}/>
                        <div className={classes.inside3Text}>Advanced Analytics</div>
                    </div>
                    <div className={classes.inside3}>
                        <img src={Icon2}/>
                        <div className={classes.inside3Text}>Organization</div>
                    </div>
                    <div className={classes.inside3}>
                        <img src={Icon2}/>
                        <div className={classes.inside3Text}>Consumer Insight</div>
                    </div>
                    <div className={classes.inside3}>
                        <img src={Icon2}/>
                        <div className={classes.inside3Text}>Bringing Your Lots Into The 21st Century</div>
                    </div>
                </div>
            </div>
            <div className={classes.btn}>
                <Button color='#FFFFFF' backgroundColor='#3C78F0'
                        OnClick={props.moveToContact}>Contact Us</Button>
            </div>
        </>
    )
}


export default PartnerSolutions2;
