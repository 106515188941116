import React from "react";
import classes from './HomeFifthPart.module.css';
import Background2 from "../../../assets/images/home/Background2.png";



function HomeFifthPart() {

    return(
        <div className={classes.backgroundStyle} style={{ 'background-image': `url(${Background2})`}}>
            <div className={classes.sixthTitle}>Review our
                <span className={classes.sixthBlueTitle}>Parking Solutions </span>to learn more about partnering.</div>
        </div>
    )
}


export default HomeFifthPart;