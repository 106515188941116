import React from "react";
import classes from './NewInput.module.css';


function NewInput (props) {

    return (
        <div
            className={classes.control}>
            {props.label && <label htmlFor={props.input.id}>{props.label}</label>}
            <div className={classes.inputDiv}>
                <input className={`${props.hasError || props.loginError? classes.invalid: ""}`}
                       {...props.input}/>
                <img src={props.image} onClick={props.onClick}/>
                {/*{props.input.value && <img src={props.image} onClick={props.onClick}/>}*/}
            </div>
            {props.hasError && <div className={classes.textDanger}>{props.errorText}</div>}
        </div>
    )
}

export default NewInput;