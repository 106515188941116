import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactUs1 from '../../assets/images/contactUs/contactUs1.png';
import ContactUs2 from '../../assets/images/contactUs/ContactUs2.png';
import Phone from '../../assets/images/contactUs/Phone.png';
import Location from '../../assets/images/contactUs/Location.png';
import Email from '../../assets/images/contactUs/Email.png';
import Information from '../../assets/images/contactUs/Information.png';
import Watch from '../../assets/images/contactUs/Watch.png';
import classes from './ContactUs.module.css';
import useValidation from "../../hooks/useValidation";
import useInput from "../../hooks/useInput";
import Input from "../../UI/Input/Input";
import {useLocation} from "react-router-dom";
import axios from "axios";
import config from "../../config";
import 'react-toastify/dist/ReactToastify.css';
import "react-toastify/dist/ReactToastify.minimal.css";
import {toast} from "react-toastify";
import Loader from "../../UI/Loader/Loader";




function ContactUs(props){
    const [isLoading, setIsLoading] = useState(false);
    const {isName,  isNotEmpty, isEmail}=useValidation();
    const routePath = useLocation();

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        onTop();
    }, [routePath]);


    const {
        value: fullname,
        isValid: fullnameIsValid,
        hasError: fullnameHasError,
        valueChangeHandler: fullnameChangeHandler,
        inputBlurHandler: fullnameBlurHandler,
        reset: resetFullname,
    } = useInput(isName);
    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);

    const{
        value:textValue,
        isValid:textValueIsValid,
        hasError:textValueHasError,
        valueChangeHandler:textValueChangeHandler,
        inputBlurHandler:textValueBlurHandler,
        reset:resetTextArea,
    } =useInput(isNotEmpty)



    let emailMessage =null;
    if(!email){
        emailMessage = "Email is required";
    }
    else if (!emailIsValid){
        emailMessage = "Invalid email";
    }



    let formIsValid = false;
    if (fullnameIsValid
        && emailIsValid
        && textValueIsValid
    ) {
        formIsValid = true;
    }

    let sendContactForm = async ()=>{
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        let formData = new FormData();
        formData.append('name', fullname);
        formData.append('email', email);
        formData.append('description', textValue);
        try {
            let response = await axios.post(`${config.baseUrl}api/contact/create`, formData, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            if(response.data.message==="Message send successfully."){
                toast.success("Form is sent successfully!");
            }
            console.log(response.data, "contact us")
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    const submitHandler =  event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        sendContactForm();
        console.log("true")
        resetFullname();
        resetEmail();
        resetTextArea()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }

    if (isLoading) return <Loader/>;

    return(
        <>
            <Header
                loginModalIsOpen={props.loginModalIsOpen}
                openLoginModal={props.openLoginModal}
                closeLoginModal={props.closeLoginModal}
                signUpModalIsOpen={props.signUpModalIsOpen}
                openSignUpModal={props.openSignUpModal}
                closeSignUpModal={props.closeSignUpModal}
            />
            <div className={classes.backgroundStyle} style={{ 'background-image': `url(${ContactUs1})`}}></div>
            <div className={classes.backgroundBlueStyle} style={{ 'background-image': `url(${ContactUs2})`}}>
                <div className="container">
                    <div className={classes.contactWhole}>
                        <div className={classes.contactLeft}>
                            <div className={classes.leftTitle}>Our Contacts</div>
                            <div className={classes.iconAndInformation}>
                                <img src={Phone} alt=""/>
                                <div className={classes.informationDiv}>
                                    <div className={classes.infoTitle}>Call</div>
                                    <div className={classes.content}>(614) 813-0532</div>
                                </div>
                            </div>
                            <div className={classes.iconAndInformation}>
                                <img src={Location} alt=""/>
                                <div className={classes.informationDiv}>
                                    <div className={classes.infoTitle}>Location</div>
                                    <div className={classes.content}>20 W court ST Cincinnati Ohio 45202</div>
                                </div>
                            </div>
                            <div className={classes.iconAndInformation}>
                                <img src={Email} alt=""/>
                                <div className={classes.informationDiv}>
                                    <div className={classes.infoTitle}>Email</div>
                                    <div className={classes.content}>Parkinpals@gmail.com</div>
                                </div>
                            </div>
                            <div className={classes.iconAndInformation}>
                                <img src={Watch} alt=""/>
                                <div className={classes.informationDiv}>
                                    <div className={classes.infoTitle}>Work Hours</div>
                                    <div className={classes.content}>Mon. - Fri. 11AM - 19PM</div>
                                </div>
                            </div>
                            <div className={classes.iconAndInformation}>
                                <img src={Information} alt=""/>
                                <div className={classes.informationDiv}>
                                    <div className={classes.infoTitle}>Additional Information</div>
                                    <div className={classes.content}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Aenean hendrerit vel mauris at tincidunt.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.contactRight}>
                            <div className={classes.rightTitle}>Send Us A Message</div>
                            <form className={classes.rightForm} onSubmit={submitHandler}>
                                <Input
                                    hasError={fullnameHasError}
                                    errorText="Please enter Full Name."
                                    width ='720px'
                                    input={{
                                        value: fullname,
                                        placeholder: "Full Name",
                                        type: "text",
                                        onChange: fullnameChangeHandler,
                                        onBlur: fullnameBlurHandler
                                    }}
                                />
                                <Input
                                    hasError={emailHasError}
                                    errorText={emailMessage}
                                    width ='720px'
                                    input={{
                                        value: email,
                                        placeholder: "Your Email Address",
                                        type: "email",
                                        onChange: emailChangeHandler,
                                        onBlur: emailBlurHandler
                                    }}
                                />
                                <div className={classes.textAreaDiv}>
                            <textarea  className={`${textValueHasError? classes.invalid : ""}`}
                                       rows="9"
                                       value={textValue}
                                       onChange={textValueChangeHandler}
                                       onKeyPress={handleKeyPress}
                                       onBlur={textValueBlurHandler}
                                       placeholder='Message'/>
                                    {textValueHasError &&  <div className={classes.textDanger}>Please fill in the textarea</div>}
                                </div>
                                <div className={classes.btnDiv}>
                                    <button className={classes.sendBtn} disabled={!formIsValid} type={"submit"}>Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}


export default ContactUs;