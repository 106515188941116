import React, {useState} from "react";
import Logo from '../../assets/images/home/Logo.png';
import WhiteArrow from '../../assets/images/home/WhiteArrow.png';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import classes from './Header.module.css';
import LoginModal from "../LoginModal/LoginModal";
import SignUpModal from "../SignUpModal/SignUpModal";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import SignUpOwner from "../SignUpOwner/SignUpOwner";
import ForgetPasswordModal from "../ForgetPasswordModal/ForgetPasswordModal";
import ResetPasswordModal from "../ResetPasswordModal/ResetPasswordModal";
import NewPasswordModal from "../NewPasswordModal/NewPasswordModal";



function Header(props) {

    const [signUpOwnerModalIsOpen, setSignUpOwnerModalIsOpen] = useState(false);
    const [verifyEmailIsOpen, setVerifyEmail] = useState(false);
    const [forgetPasswordIsOpen, setForgetPassword] = useState(false);
    const [passwordResetIsOpen, setPasswordResetIsOpen] = useState(false);
    const [newPasswordModalIsOpen, setNewPasswordModalIsOpen] = useState(false);
    const [emailValue, setEmailValue]=useState("");
    let accessToken=localStorage.getItem('token') || sessionStorage.getItem('token') || ""

    const {pathname} = useLocation();
    const navigate=useNavigate();


    function openSignUpOwnerModal() {
        setSignUpOwnerModalIsOpen(true);
    }

    function closeSignUpOwnerModal() {
        setSignUpOwnerModalIsOpen(false);
    }

    function openVerifyEmailModal() {
        setVerifyEmail(true);
    }

    function closeVerifyEmailModal() {
        setVerifyEmail(false);
    }

    function openForgetPasswordModal() {
        setForgetPassword(true);
    }

    function closeForgetPasswordModal() {
        setForgetPassword(false);
    }


    function openPasswordResetModal() {
        setPasswordResetIsOpen(true);
    }

    function closePasswordResetModal() {
        setPasswordResetIsOpen(false);
    }

    function openNewPasswordModal() {
        setNewPasswordModalIsOpen(true)
    }

    function closeNewPassworModal() {
        setNewPasswordModalIsOpen(false)
    }

    function CallBack(emailData){
        setEmailValue (emailData);
    }




    return(
        <>
            <div className={classes.headerWhole}>
                <div onClick={()=>navigate('/')} className={classes.logo}>
                    <img src={Logo} alt='logo'/>
                </div>
                <div className={classes.headerPart2}>
                    {
                        !accessToken?
                          <div className={classes.nav_link}
                                  onClick={()=>props.openLoginModal()}>Pay to park</div>:
                            <NavLink
                                to="/my-profile"
                                className={({isActive}) =>
                                    classes['nav_link' + (pathname === '/my-profile'  && isActive ? '_active' : '')]
                                }
                            >Pay to park </NavLink>
                    }
                    <NavLink
                        to="/additional-services"
                        className={({isActive}) =>
                            classes['nav_link' + (pathname === '/additional-services'  && isActive ? '_active' : '')]
                        }
                    >
                        Additional Services
                    </NavLink>


                    <NavLink
                        className={({isActive}) =>
                            classes['nav_link' +
                            ((pathname === '/partner-solutions' && isActive) || (pathname === '/partner-solutions' && !isActive)
                                ? '_active' : '' ) ]
                        }
                        to="/partner-solutions" >
                        Partner Solutions
                    </NavLink>

                    <NavLink
                        className={({isActive}) =>
                            classes['nav_link' +
                            ((pathname === '/contact-us' && isActive) || (pathname === '/contact-us' && !isActive)
                                ? '_active' : '' ) ]
                        }
                        to="/contact-us" >
                        Contact Us
                    </NavLink>
                    {!accessToken && <div className={classes.activePinkBtn} onClick={()=>props.openLoginModal()}>Login
                        <img src={WhiteArrow} className={classes.whiteArrow}/> </div>}
                </div>
            </div>
            <LoginModal loginModalIsOpen={props.loginModalIsOpen} closeLoginModal={props.closeLoginModal}
                       openSignUpModal={props.openSignUpModal} openSignUpOwnerModal={openSignUpOwnerModal}
                        openForgetPasswordModal={openForgetPasswordModal}
                        setAccessToken={props.setAccessToken}
            />
            <SignUpModal signUpModalIsOpen={props.signUpModalIsOpen} closeSignUpModal={props.closeSignUpModal}
                         openVerifyEmailModal={openVerifyEmailModal}/>
            <SignUpOwner signUpOwnerModalIsOpen={signUpOwnerModalIsOpen} closeSignUpOwnerModal={closeSignUpOwnerModal}
                         openVerifyEmailModal={openVerifyEmailModal}/>
            <VerifyEmail verifyEmailIsOpen={verifyEmailIsOpen}
                         closeVerifyEmailModal={closeVerifyEmailModal} openLoginModal={props.openLoginModal}/>
            <ForgetPasswordModal forgetPasswordIsOpen={forgetPasswordIsOpen} closeForgetPasswordModal={closeForgetPasswordModal}
                                 openPasswordResetModal={openPasswordResetModal} openSignUpModal={props.openSignUpModal}
                                 handleCallBack={CallBack}
            />
            <ResetPasswordModal passwordResetIsOpen={passwordResetIsOpen} closePasswordResetModal={closePasswordResetModal}
                                openNewPasswordModal={openNewPasswordModal} emailValue={emailValue}/>
            <NewPasswordModal newPasswordModalIsOpen={newPasswordModalIsOpen} closeNewPassworModal={closeNewPassworModal}
                              openLoginModal={props.openLoginModal} emailValue={emailValue}/>
        </>

    )
}


export default Header;
