import React, {useEffect, useState} from "react";
import Home from "./app/pages/Home/Home";
import Pusher from 'pusher-js';
import AdditionalServices from "./app/pages/AdditionalServices/AdditionalServices";
import {
    BrowserRouter,
    Routes,
    Route, Navigate,
} from "react-router-dom";
import './App.css';
import PartnerSolutions from "./app/pages/PartnerSolutions/PartnerSolutions";
import ContactUs from "./app/pages/ContactUs/ContactUs";
import Settings from "./app/pages/Settings/Settings";
import MyProfile from "./app/pages/Myprofile/MyProfile";
import SuccessReserved from "./app/pages/SuccessReserved/SuccessReserved";
import ReserveParking from "./app/pages/ReserveParking/ReserveParking";
import Reservations from "./app/pages/Reservations/Reservations";
import {useDispatch, useSelector} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {setUserData} from "./app/Features/User/UserSlice";
import axios from "axios";
import config from "./app/config";

function App() {

    let [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
    const [signUpModalIsOpen, setSignUpModalIsOpen] = useState(false);
    function openLoginModal() {
        setLoginModalIsOpen(true);
    }

    function closeLoginModal() {
        setLoginModalIsOpen(false);
    }

    function openSignUpModal() {
        setSignUpModalIsOpen(true);
    }

    function closeSignUpModal() {
        setSignUpModalIsOpen(false);
    }

    let [accessToken, setAccessToken] = useState(localStorage.getItem('token') || sessionStorage.getItem('token') ||"");
    let [role, setRole] = useState(localStorage.getItem('role')  || sessionStorage.getItem('role') || "");
    console.log(accessToken, "accessToken");
    const dispatch=useDispatch();

    useEffect(() => {
        getAuthUserData();
    }, [accessToken]);


    let getAuthUserData = async () => {
        let token= sessionStorage.getItem('token') || localStorage.getItem('token')
        try {
            let response = await axios.get(`${config.baseUrl}api/user`,{
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            });
            console.log(response.data, "auth request data");
            dispatch(setUserData(response.data));
        } catch (error) {
            console.log(error, 'auth request  error');
            console.log(error.response, 'auth request  error response');
        }
    }




    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={ <Home
                        loginModalIsOpen={loginModalIsOpen}
                        openLoginModal={openLoginModal}
                        closeLoginModal={closeLoginModal}
                        signUpModalIsOpen={signUpModalIsOpen}
                        openSignUpModal={openSignUpModal}
                        closeSignUpModal={closeSignUpModal}
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                        setRole={setRole}/>}/>
                    <Route exact path="/additional-services" element={ <AdditionalServices
                        loginModalIsOpen={loginModalIsOpen}
                        openLoginModal={openLoginModal}
                        closeLoginModal={closeLoginModal}
                        signUpModalIsOpen={signUpModalIsOpen}
                        openSignUpModal={openSignUpModal}
                        closeSignUpModal={closeSignUpModal}
                    />}/>
                    <Route exact path="/partner-solutions" element={ <PartnerSolutions
                        loginModalIsOpen={loginModalIsOpen}
                        openLoginModal={openLoginModal}
                        closeLoginModal={closeLoginModal}
                        openSignUpModal={openSignUpModal}
                        closeSignUpModal={closeSignUpModal}
                        signUpModalIsOpen={signUpModalIsOpen}
                    />}/>
                    <Route exact path="/contact-us" element={<ContactUs
                        loginModalIsOpen={loginModalIsOpen}
                        openLoginModal={openLoginModal}
                        closeLoginModal={closeLoginModal}
                        openSignUpModal={openSignUpModal}
                        closeSignUpModal={closeSignUpModal}
                        signUpModalIsOpen={signUpModalIsOpen}
                    />}/>
                    {accessToken ?
                        <>
                            <Route exact path="/my-profile" element={<MyProfile/>}/>
                            <Route path="/reserve-parking/:id_param" element={<ReserveParking/>}/>
                            <Route exact path="/success-reserved" element={<SuccessReserved/>}/>
                            <Route exact path="/settings" element={<Settings/>}/>
                            <Route exact path="/reservations" element={<Reservations/>}/>
                        </>:   <Route path="*"  element={<Navigate to="/"/>}/>
                    }
                </Routes>
            </BrowserRouter>
        </>

  );
}

export default App;
