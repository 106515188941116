import React from "react";
import Picture1 from '../../../assets/images/additionalServices/Picture1.png';
import Picture2 from '../../../assets/images/additionalServices/Picture2.png';
import Picture3 from '../../../assets/images/additionalServices/Picture3.png';
import Picture4 from '../../../assets/images/additionalServices/Picture4.png';
import Plus from '../../../assets/images/additionalServices/Plus.png';
import Icon1 from '../../../assets/images/additionalServices/Icon1.png';
import Icon2 from '../../../assets/images/additionalServices/Icon2.png';
import Icon3 from '../../../assets/images/additionalServices/Icon3.png';
import classes from './AdditionalServicesPart1.module.css';
import BackgroundWave from '../../../assets/images/additionalServices/BackgroundWave.png';



function AdditionalServicesPart1() {


    const additionalServicesArray=[
        {
            img:Picture1,
            title:"Glass Repair"
    },
        {
            img:Picture2,
            title:"Tire Services"
        },
        {
            img:Picture3,
            title:"Battery Jumping"
        },
        {
            img:Picture4,
            title:"Gas Services"
        },
    ]

    const renderServices=additionalServicesArray.map((item, index)=>
        <div className={classes.serviceDiv} key={index}>
            <div><img src={item.img}/>
                <div className={classes.serviceNameDiv}>{item.title}
                    <img className={classes.plusDiv} src={Plus}/></div>
            </div>
        </div>)



    return(
        <>
            <div className="container">
                <div className={classes.part1Whole}>
                    {renderServices}
                </div>
                <div className="blackTitle">3 Easy Steps To Get The Help You Need...</div>
            </div>
            <div className={classes.backgroundWave} style={{ 'background-image': `url(${BackgroundWave})`}}>
            <div className='container'>
                <div className={classes.stepsWhole}>
                    <div className="card marginRight">
                        <div className={classes.iconDiv}><img src={Icon1}/></div>
                        <div className={classes.cardName}>Call or complete the online form Parkin'Pal</div>
                    </div>
                    <div className="card marginRight">
                        <div className={classes.iconDiv}><img src={Icon2}/></div>
                        <div className={classes.cardName}>The form is sent to Parkin'Pal Partner</div>
                    </div>
                    <div className="card">
                        <div className={classes.iconDiv}><img src={Icon3}/></div>
                        <div className={classes.cardName}>A representative will contacts you to schedule a repair</div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}


export default AdditionalServicesPart1;
