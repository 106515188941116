import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config";
import Modal from 'react-modal';
import useInput from "../../hooks/useInput";
import useValidation from "../../hooks/useValidation";
import classes from './NewPasswordModal.module.css';
import EyeImage from "../../assets/images/EyeImage.png";
import ClosedEye from "../../assets/images/ClosedEye.png";
import NewInput from "../../UI/NewInput/NewInput";



function NewPasswordModal(props){

    const customStyles = {
        content: {
            maxWidth: '697px',
            overflowY: 'hidden',
            padding:'0',
            width: '100%',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:'10000'
        },
    };

    const {isPassword}=useValidation();
    const [changePasswordError, setChangePasswordError] = useState(null);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
        showPassFalse:showPassFalse,
        isTouched: passwordIsTouched
    } = useInput(isPassword);

    const {
        value: confirmPasswordValue,
        isValid: confirmPasswordIsValid,
        valueChangeHandler: confirmPasswordChangeHandler,
        inputBlurHandler: confirmPasswordBlurHandler,
        togglePassword: confirmPasswordShowHandler,
        passwordShown: confirmPasswordShow,
        reset: resetConfirmPassword,
        isTouched: confirmPasswordIsTouched
    } = useInput(isPassword);

    useEffect(() => {
        if(passwordIsTouched || confirmPasswordIsTouched){
            setChangePasswordError("")
        }
    },[passwordIsTouched,confirmPasswordIsTouched]);


    let hasError = false;
    let confirmPasswordMessage=null;
    if(password !== confirmPasswordValue){
        hasError = true;
        confirmPasswordMessage = "Passwords do not match"
    }


    let formIsValid = false;
    if (passwordIsValid && confirmPasswordIsValid) {
        formIsValid = true;
    }


    function closeAndResetChangePasswordModal (){
        props.closeNewPassworModal();
        resetPassword();
        resetConfirmPassword();
        showPassFalse();
        setChangePasswordError("")
    }

    const postChangePassword = async () => {
        let formData = new FormData();
        formData = {
            email: props.emailValue,
            password: password,
            password_confirmation:confirmPasswordValue
        }
        console.log(formData, "formData")
        try {
            let response = await axios.post(`${config.baseUrl}api/reset/password`, formData);
            console.log(response.data, "response.data");
            if(response.data.success===true){
                props.closeNewPassworModal();
                props.openLoginModal()
            }
        } catch (error) {
            console.log(error, "resetPasswordModalError")
            setChangePasswordError("Something went wrong");
        }
    }

    const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        await postChangePassword();
        await resetPassword();
        await showPassFalse()
        resetConfirmPassword()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }

    return(
        <Modal
            isOpen={props.newPasswordModalIsOpen}
            onRequestClose={closeAndResetChangePasswordModal}
            // onAfterOpen={props.closeLoginModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <div  className={classes.modalWhole}>
                <div className={classes.modalTitle}>Set New Password</div>
                <div className={classes.modalSubTitle}>Here you can set new password for signing in to acme team space. Make sure you remember
                    this password and keep it in a safe place. You can change the password at any time by visiting
                    Password tab in the Settings menu.</div>
                <form onSubmit={submitHandler}>
                    <NewInput
                        hasError = {passwordHasError}
                        errorText="Please enter password. (Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)"
                        image ={passwordShow ? EyeImage : ClosedEye}
                        onClick={passwordShowHandler}
                        input={{
                            value: password,
                            placeholder: "Password",
                            type:passwordShow ? "text" : "password",
                            onChange: passwordChangeHandler,
                            onBlur: passwordBlurHandler,
                        }}
                    />
                    <NewInput
                        hasError = {hasError && confirmPasswordIsTouched}
                        errorText={confirmPasswordMessage}
                        image ={confirmPasswordShow ? EyeImage : ClosedEye}
                        onClick={confirmPasswordShowHandler}
                        input={{
                            value: confirmPasswordValue,
                            placeholder: "Confirm Password",
                            type: confirmPasswordShow ? "text" : "password",
                            onChange: confirmPasswordChangeHandler,
                            onBlur: confirmPasswordBlurHandler,
                            onKeyPress:handleKeyPress
                        }}
                    />
                    <div className={classes.btnErrorDiv}>
                        <button className={classes.newPasswordBtn} disabled={!formIsValid} type={"submit"}>Confirm</button>
                        {changePasswordError && <div className={classes.changePasswordError}>{changePasswordError}</div>}
                    </div>
                </form>
            </div>
        </Modal>
    )
}


export default NewPasswordModal;

