import React from "react";


const regExpName = /([A-Z][a-z]*)([\\s\\\'-][A-Z][a-z]*)*/;
const regExpNameSurname= /^[a-zA-Z ]+$/;
const regExpEmail = /\w+(\.|-|_)?\w+@\w+\.\w{2,3}/;
const regExpPass =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
const regExpPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
const regExpCardNumber =  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\\d{3})\\d{11})$/;
const regExpCardHolder = /^[a-zA-Z]+ [a-zA-Z]+$/;
const regExpCVV = /^[0-9]{3,4}$/;
const regExpExpireDate = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;


const isName = value => regExpName.test(value);
const isNameSurname =value =>regExpNameSurname.test(value);
const isEmail = value => regExpEmail.test(value);
const isPassword = value => regExpPass.test(value);
const isNotEmpty = (value) => value.trim() !== '';
const isPhoneNumber = value => regExpPhone.test(value);
const isCardNumber = value => regExpCardNumber.test(value);
const isCardHolder = value => regExpCardHolder.test(value);
const isCVV = value => regExpCVV.test(value);
const isExpDate = value => regExpExpireDate.test(value);


const useValidation = () => {

    return {
        isName,
        isNameSurname,
        isEmail,
        isPassword,
        isPhoneNumber,
        isNotEmpty,
        isCardNumber,
        isCardHolder,
        isCVV,
        isExpDate
    }
}

export default useValidation;


// postalCode: {
//     validation: new RegExp("^[0-9]{5}(-[0-9]{4})?$"), // USPOSTAL CODE
//         errorMessage: "Invalid postal code.",
// },