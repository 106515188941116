import React, {useState} from "react";
import useValidation from "../../../hooks/useValidation";
import useInput from "../../../hooks/useInput";
import classes from './PaymentMethods.module.css';
import Input from "../../../UI/Input/Input";
import Close from "../../../assets/images/close.png";




function PaymentMethods (props){

    const [showAddPaymentMethod, setShowAddPaymentMethod]=useState(false);
    const {isCardNumber, isExpDate, isCVV, isCardHolder}=useValidation();


    const {
        value: cardNumber,
        isValid: cardNumberIsValid,
        hasError: cardNumberHasError,
        valueChangeHandler:cardNumberChangeHandler,
        inputBlurHandler: cardNumberBlurHandler,
        reset: resetCardNumber,
    } = useInput(isCardNumber);

    const {
        value: expiration,
        isValid: expirationIsValid,
        hasError: expirationHasError,
        valueChangeHandler:expirationChangeHandler,
        inputBlurHandler: expirationBlurHandler,
        reset: resetExpiration,
    } = useInput(isExpDate);

    const {
        value: securityCode,
        isValid: securityCodeIsValid,
        hasError: securityCodeHasError,
        valueChangeHandler:securityCodeChangeHandler,
        inputBlurHandler: securityCodeBlurHandler,
        reset: resetSecurityCode,
    } = useInput(isCVV);

    const {
        value: cardholder,
        isValid: cardholderIsValid,
        hasError: cardholderHasError,
        valueChangeHandler:cardholderChangeHandler,
        inputBlurHandler: cardholderBlurHandler,
        reset: resetCardholder,
    } = useInput(isCardHolder);


    let formIsValid = false;
    if (cardNumberIsValid &&
        expirationIsValid &&
        securityCodeIsValid &&
        cardholderIsValid ) {
        formIsValid = true;
    }

    let expiration_month=expiration.slice(0,2);
    let expiration_year =expiration.slice(3,5);


    const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        let formData = new FormData();
        formData.append('card_number', cardNumber );
        formData.append('expiration_year', expiration_year);
        formData.append('expiration_month',expiration_month);
        formData.append('cvc', securityCode );
        formData.append('name_on_card',cardholder);
        props.onAddCard(formData);
        closeAndResetPayment();
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }


    function closeAndResetPayment () {
        setShowAddPaymentMethod(!showAddPaymentMethod);
        resetCardNumber();
        resetExpiration();
        resetSecurityCode();
        resetCardholder();
    }


    return(
        <div className="settingsWhole">
            <div className="settingsTitle">
                {!showAddPaymentMethod ?
                    <div className="titleDetails">Payment Methods</div> :
                    <>
                        <div className="titleDetails"> Add Payment Methods</div>
                        <div className="edit" onClick={()=>closeAndResetPayment()}>
                            <img src={Close} className="editImg" alt=""/>Close</div>
                    </>
                }
            </div>
            {(!showAddPaymentMethod && props?.paymentData?.length ===0)?
                <>
                    <div className="notification">You haven’t added a payment method yet.</div>
                    <div className="blueBtn" onClick={()=>setShowAddPaymentMethod(!showAddPaymentMethod)}>+ Add Card</div>
                </> :(!showAddPaymentMethod && props?.paymentData?.length !==0) ?
                    <>
                        <div className={classes.cardsWhole}>
                            {props?.paymentData?.map(item => (
                                <ul
                                    key={item.id}
                                    className={classes.cardDiv}
                                ><li>{`${item.type + " " + "**** **** **** " + item.cardNo}`}</li>
                                </ul>
                            ))}
                        </div>
                        <div className="blueBtn" onClick={()=>setShowAddPaymentMethod(!showAddPaymentMethod)}>+ Add Payment Methods</div>
                    </>
                    :
                <>
                    <form
                        onSubmit={submitHandler}
                    >
                        <Input
                            hasError={cardNumberHasError}
                            errorText="Invalid card number."
                            label='Card Number'
                            color='#10142E'
                            width ='100%'
                            input={{
                                value: cardNumber,
                                placeholder: "",
                                type: "number",
                                onChange: cardNumberChangeHandler,
                                onBlur: cardNumberBlurHandler
                            }}
                        />
                        <Input
                            hasError={expirationHasError}
                            errorText="Invalid expiry date."
                            label='Expiration (MM/YY)'
                            color='#10142E'
                            width ='100%'
                            input={{
                                value: expiration,
                                placeholder: "",
                                type: "text",
                                onChange: expirationChangeHandler,
                                onBlur: expirationBlurHandler
                            }}
                        />
                        <Input
                            hasError={securityCodeHasError}
                            errorText="Invalid security code."
                            label='CVV/CVC Code'
                            color='#10142E'
                            width ='100%'
                            input={{
                                value: securityCode,
                                placeholder: "",
                                type: "number",
                                onChange: securityCodeChangeHandler,
                                onBlur: securityCodeBlurHandler
                            }}
                        />
                        <Input
                            hasError={cardholderHasError}
                            errorText="Insert cardholder."
                            label='Cardholder'
                            color='#10142E'
                            width ='100%'
                            input={{
                                value: cardholder,
                                placeholder: "",
                                type: "text",
                                onChange:cardholderChangeHandler,
                                onBlur: cardholderBlurHandler,
                                onKeyPress:handleKeyPress
                            }}
                        />
                        <button className={classes.submitCard} disabled={!formIsValid}>Submit Card</button>
                    </form>
                </>
            }
        </div>
    )
}


export default PaymentMethods;