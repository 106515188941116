import React from "react";
import Button from "../../UI/Button/Button";
import Header from "../../components/Header/Header";
import Background1 from '../../assets/images/home/Background1.png';
import 'react-toastify/dist/ReactToastify.css';
import classes from './Home.module.css';
import HomeSecondPart from "../../components/Home/HomeSecondPart/HomeSecondPart";
import HomeThirdPart from "../../components/Home/HomeThirdPart/HomeThirdPart";
import HomeFourthPart from "../../components/Home/HomeFourthPart/HomeFourthPart";
import HomeFifthPart from "../../components/Home/HomeFifthPart/HomeFifthPart";
import GeneralQuestions from "../../components/Home/GeneralQuestions/GeneralQuestions";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router-dom";


function Home(props) {


    const navigate=useNavigate();

    return(
        <>
            <Header
                loginModalIsOpen={props.loginModalIsOpen}
                openLoginModal={props.openLoginModal}
                closeLoginModal={props.closeLoginModal}
                signUpModalIsOpen={props.signUpModalIsOpen}
                openSignUpModal={props.openSignUpModal}
                closeSignUpModal={props.closeSignUpModal}
                // onTokenHandler={props.onTokenHandler}
                setAccessToken={props.setAccessToken}
            />
            <div className={classes.backgroundStyle} style={{ 'background-image': `url(${Background1})`}}>
                <div className={classes.titleHomeBlue}>FOR ALL YOUR
                    <span className={classes.titleHomePink}>PARKING NEEDS </span>
                </div>
                <div className={classes.homeSubtitle}>Online Payments for hourly & Event Parking, Tire services,
                    Battery Jumping, Lockouts and More.</div>
                <div className={classes.btnsDiv}>
                    <div className={classes.blueBtn}>
                        <Button backgroundColor='#0B2F89' color="#FFFFFF" OnClick={()=>props.openLoginModal()}>Park Now</Button>
                    </div>
                    <Button backgroundColor='#FFFFFF' color="#EB61EB" border="1px solid #EB61EB"
                            OnClick={()=>navigate('/additional-services')}>
                        Other Services</Button>
                </div>
            </div>
            <HomeSecondPart/>
            <HomeThirdPart/>
            <HomeFourthPart/>
            <HomeFifthPart/>
            <GeneralQuestions/>
            <Footer/>
            </>

    )
}

export default Home;



