import {createSlice} from "@reduxjs/toolkit";


const initialState={
    data: null,
}


export const parkingZoneSlice = createSlice({
    name:'parkingZone',
    initialState,
    reducers: {
        setParkingZoneData: (state, action) => {
            state.data = action.payload;
        },
        changeZoneParkingsAvailability:(state,action)=>{
           let array=state.data.parkings;
           for(let i=0; i<array.length; i++){
               if (array[i].parkingAvailabiltyStyle && array[i].id !==action.payload){
                   array[i].parkingAvailabiltyStyle=false
               }
               else if(array[i].parkingAvailabiltyStyle && array[i].id ===action.payload){
                   array[i].parkingAvailabiltyStyle=false
               }
               else if(!array[i].parkingAvailabiltyStyle && array[i].id ===action.payload){
                   array[i].parkingAvailabiltyStyle=true
               }
           }
        }
    }
})

export const {setParkingZoneData, changeZoneParkingsAvailability}=parkingZoneSlice.actions;
export default parkingZoneSlice.reducer;