import React, {useState, useEffect, useRef} from "react";
import Logo from '../../assets/images/home/Logo.png';
import Menu from '../../assets/images/menu.png';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import classes from './NewHeader.module.css';
import LoginModal from "../LoginModal/LoginModal";
import {setUserData} from "../../Features/User/UserSlice";
import SignUpModal from "../SignUpModal/SignUpModal";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import SignUpOwner from "../SignUpOwner/SignUpOwner";



function Header(props) {

    const dispatch=useDispatch();
    const customer = useSelector((state)=>state.user.data);
    let userName = customer ? customer.first_name + ' ' + customer.last_name : '';


    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
    const [signUpModalIsOpen, setSignUpModalIsOpen] = useState(false);
    const [signUpOwnerModalIsOpen, setSignUpOwnerModalIsOpen] = useState(false);
    const [verifyEmailIsOpen, setVerifyEmail] = useState(false);
    const [dropdownShow, setDropDownShow] = useState(false);
    let closeHeaderDropDawnRef = useRef(null);
    let closeHeaderDropDawnContentRef = useRef(null);

    const handleClickDropdown = () => {
        setDropDownShow(!dropdownShow)
    }
    const handleClickOutSide = (e) => {
        let closeDropDawnRef = closeHeaderDropDawnRef;
        let contentRef = closeHeaderDropDawnContentRef;
        if (contentRef.current && !contentRef.current.contains(e.target) && !closeDropDawnRef.current.contains(e.target)) {
            setDropDownShow(false)
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    const {pathname} = useLocation();
    const navigate=useNavigate();

    function openLoginModal() {
        setLoginModalIsOpen(true);
    }

    function closeLoginModal() {
        setLoginModalIsOpen(false);
    }

    function openSignUpModal() {
        setSignUpModalIsOpen(true);
    }

    function closeSignUpModal() {
        setSignUpModalIsOpen(false);
    }

    function openSignUpOwnerModal() {
        setSignUpOwnerModalIsOpen(true);
    }

    function closeSignUpOwnerModal() {
        setSignUpOwnerModalIsOpen(false);
    }

    function openVerifyEmailModal() {
        setVerifyEmail(true);
    }

    function closeVerifyEmailModal() {
        setVerifyEmail(false);
    }

    const removeTokenHandler=()=>{
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        localStorage.removeItem('email');
        dispatch(setUserData(null))
            //api kptsnel
        navigate('/')
    }


    return(
        <>
            <div className={classes.headerWhole}>
                <div onClick={()=>navigate('/')} className={classes.logo}>
                    <img src={Logo} alt='logo'/>
                </div>
                <div className={classes.headerPart2}>
                    <div className={classes.user}>{userName}</div>
                    <div className={classes.menuBtn}
                    ><img src={Menu}
                          onClick={handleClickDropdown} ref={closeHeaderDropDawnRef}/></div>
                    {
                        dropdownShow &&
                        <div
                            className="d-flex fd-column header_dropDown_content f-400"
                            ref={closeHeaderDropDawnContentRef}>
                            <NavLink
                                to="/"
                                className={({isActive}) =>
                                    classes['nav_link' + (pathname === '/'  && isActive ? '_active' : '')]
                                }
                            >
                                Home
                            </NavLink>
                            <NavLink
                                className={({isActive}) =>
                                    classes['nav_link' +
                                    ((pathname === '/settings' && isActive) || (pathname === '/settings' && !isActive)
                                        ? '_active' : '' ) ]
                                }
                                to="/settings"
                            >
                                Settings
                            </NavLink>
                            <NavLink
                                className={({isActive}) =>
                                    classes['nav_link' +
                                    ((pathname === '/reservations' && isActive) || (pathname === '/reservations' && !isActive)
                                        ? '_active' : '' ) ]
                                }
                                to="/reservations"
                            >
                                Reservations
                            </NavLink>
                            <div className={classes.logOut} onClick={()=>removeTokenHandler()}>Log Out</div>
                        </div>
                    }
                </div>
            </div>
            <LoginModal loginModalIsOpen={loginModalIsOpen} closeLoginModal={closeLoginModal}
                        openSignUpModal={openSignUpModal} openSignUpOwnerModal={openSignUpOwnerModal}
                        setAccessToken={props.setAccessToken}/>
            <SignUpModal signUpModalIsOpen={signUpModalIsOpen} closeSignUpModal={closeSignUpModal}
                         openVerifyEmailModal={openVerifyEmailModal}/>
            <SignUpOwner signUpOwnerModalIsOpen={signUpOwnerModalIsOpen} closeSignUpOwnerModal={closeSignUpOwnerModal}
                         openVerifyEmailModal={openVerifyEmailModal}/>
            <VerifyEmail verifyEmailIsOpen={verifyEmailIsOpen}
                         closeVerifyEmailModal={closeVerifyEmailModal} openLoginModal={openLoginModal}/>
        </>

    )
}


export default Header;
