import Pusher from 'pusher-js';

// const pusher = new Pusher({
//     appId: "1547778",
//     key: "1ba4cde4fa55d12b2c52",
//     secret: "ee05205bf762e3d4e6ac",
//     cluster: "mt1",
//     encrypted: true,
// });

let pusher = new Pusher('1ba4cde4fa55d12b2c52', {
    cluster: 'mt1',
    encrypted: true,
});


export default pusher;