import React from "react";
import PartnerSolutions1 from "../../components/PartnerSolutionsComponent/PartnerSolutions1/PartnerSolutions1";
import PartnerSolutions2 from "../../components/PartnerSolutionsComponent/PartnerSolutions2/PartnerSolutions2";
import Solutions1 from '../../assets/images/partnerSolutions/Solutions1.png';
import classes from './PartnerSolutions.module.css';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useNavigate} from "react-router-dom";



function PartnerSolutions(props){

    const navigate= useNavigate();

    const moveToContact = ()=>{
        navigate('/contact-us')
    }



    return(
            <>
                <Header
                    loginModalIsOpen={props.loginModalIsOpen}
                    openLoginModal={props.openLoginModal}
                    closeLoginModal={props.closeLoginModal}
                    signUpModalIsOpen={props.signUpModalIsOpen}
                    openSignUpModal={props.openSignUpModal}
                    closeSignUpModal={props.closeSignUpModal}
                />
                <div className={classes.backgroundStyle} style={{ 'background-image': `url(${Solutions1})`}}/>
                <PartnerSolutions1/>
                <PartnerSolutions2 moveToContact={moveToContact}/>
                <Footer/>
                </>
    )
}


export default PartnerSolutions;