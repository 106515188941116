import React from "react";
import classes from './HomeFourthPart.module.css';



function HomeFourthPart() {

    return(
        <div className={classes.fourthWhole}>
            <div className={classes.fourthTitle}>Looking to partner?</div>
            <div className={classes.fourthSubTitle}>Count on your pals for your parking needs</div>
            <div className={classes.fourthText}>When it comes to parking there is nothing we can’t do. We strive to
                provide our community with a safe, convenient, parking system that helps ensure the easiest part of
                making memories in our beautiful city, is parking. Not only do we partner with local businesses and
                events to provide commercial parking, we give back to our community by partnering with local charities
                including the Wilkinson Father Foundation, Equitas. and more.</div>
        </div>
    )
}

export default HomeFourthPart;