import React, {useState} from "react";
import useValidation from "../../../hooks/useValidation";
import useInput from "../../../hooks/useInput";
import Input from "../../../UI/Input/Input";
import classes from './AdditionalServicesPart2.module.css';
import axios from "axios";
import config from "../../../config";
import {toast} from "react-toastify";
import Loader from "../../../UI/Loader/Loader";



function AdditionalServicesPart2(){


    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({
        location: '',
        damagedWindow: '',
        operateSafely:''
    });

    const [errors, setErrors] = useState({
        location: null,
        damagedWindow: null,
        operateSafely:null
    });

    const handleChange = ({target: {name, value}}) => {
        setValues({
            ...values,
            [name]: value,
        })
        console.log(name, value)
        setErrors({
            ...errors,
            [name]: null
        });
    };


    const {isNotEmpty, isEmail, isPhoneNumber} = useValidation();

    const {
        value: fullname,
        isValid: fullnameIsValid,
        hasError: fullnameHasError,
        valueChangeHandler: fullnameChangeHandler,
        inputBlurHandler: fullnameBlurHandler,
        reset: resetFullname,
    } = useInput(isNotEmpty);

    const {
        value: phoneNumber,
        isValid: phoneNumberIsValid,
        hasError: phoneNumberHasError,
        valueChangeHandler: phoneNumberChangeHandler,
        inputBlurHandler: phoneNumberBlurHandler,
        reset: resetPhoneNumber,
    } = useInput(isPhoneNumber);

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);

    const {
        value: vinNumber,
        isValid: vinNumberIsValid,
        hasError: vinNumberHasError,
        valueChangeHandler: vinNumberChangeHandler,
        inputBlurHandler: vinNumberBlurHandler,
        reset: resetVinNumber,
    } = useInput(isNotEmpty);


    let emailMessage = null;
    if (!email) {
        emailMessage = "Email is required";
    } else if (!emailIsValid) {
        emailMessage = "Invalid email";
    }

    let formIsValid = false;
    if (fullnameIsValid
        && emailIsValid
        && phoneNumberIsValid
        && vinNumberIsValid
    ) {
        formIsValid = true;
    }

    const submitHandler = event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        let valid = true;
        let locationMessage=null;
        let damagedWindowMessage=null;
        let operateSafelyMessage=null;
        if(!values.location){
            locationMessage ="Please choose location!";
            valid = false
        }
        if(!values.damagedWindow){
            damagedWindowMessage ="Please choose which window is damaged!";
            valid = false
        }

        if(!values.operateSafely){
            operateSafelyMessage="Please choose required field!";
            valid = false
        }
        setErrors({
            location: locationMessage,
            damagedWindow: damagedWindowMessage,
            operateSafely:operateSafelyMessage
        })
        if(!valid){
            return;
        }
        sendRequiredForm();
        // navigate('/');
        resetFullname();
        resetEmail();
        resetPhoneNumber();
        resetVinNumber()
    };

    let sendRequiredForm = async ()=>{
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        let formData = new FormData();
        formData.append('full_name', fullname);
        formData.append('phone_number', phoneNumber);
        formData.append('email', email);
        formData.append('vin_number', vinNumber);
        formData.append('location', values.location);
        formData.append('damaged', values.damagedWindow);
        formData.append('operate_vehicle_safely', values.operateSafely );
        try {
            let response = await axios.post(`${config.baseUrl}api/repair/create`, formData, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            // if(response.data.message==="Message send successfully."){
                toast.success("Form is sent successfully!");
            // }
            console.log(response.data, "contact us")
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    if (isLoading) return <Loader/>;

    return(
        <>
            <div className={classes.repairRequest}>Submit A Repair Request</div>
            <form className={classes.formStyle}
                onSubmit={submitHandler}
            >
                <Input
                    hasError={fullnameHasError}
                    errorText="Please enter Full Name."
                    width ='1026px'
                    input={{
                        value: fullname,
                        placeholder: "Full Name",
                        type: "text",
                        onChange: fullnameChangeHandler,
                        onBlur: fullnameBlurHandler
                    }}
                />
                <div className={classes.inputDiv}>
                    <Input
                        hasError={phoneNumberHasError}
                        errorText="Please enter phone number."
                        width ='505px'
                        input={{
                            value: phoneNumber,
                            placeholder: "Phone Number",
                            type: "text",
                            onChange: phoneNumberChangeHandler,
                            onBlur: phoneNumberBlurHandler
                        }}
                    />
                    <Input
                        hasError={emailHasError}
                        errorText={emailMessage}
                        width ='505px'
                        input={{
                            value: email,
                            placeholder: "Your Email Address",
                            type: "email",
                            onChange: emailChangeHandler,
                            onBlur: emailBlurHandler
                        }}
                    />
                </div>
                <div className={classes.inputDiv}>
                    <Input
                        hasError={vinNumberHasError}
                        errorText="Please enter vin number."
                        width ='505px'
                        input={{
                            value: vinNumber,
                            placeholder: "Vin Number",
                            type: "text",
                            onChange: vinNumberChangeHandler,
                            onBlur: vinNumberBlurHandler
                        }}
                    />
                    <div className={classes.selectDiv}>
                        <select
                                className={`${errors.location===null? classes.selectInput : classes.selectInputRed}`}
                                id="location"
                                onChange={handleChange}
                                name="location">
                            <option className={classes.selectOption} selected disabled hidden value={values.location}>Select your location</option>
                            <option value="Location1">Location1</option>
                            <option value="Location2">Location2</option>
                            <option value="Location3">Location3</option>
                            <option value="Location4">Location4</option>
                        </select>
                        <div className={classes.textDangerServices}>
                            {errors.location}
                        </div>
                    </div>
                </div>
                <div className={classes.inputDiv}>
                    <div className={classes.selectDiv}>
                        <select
                            className={`${errors.damagedWindow===null? classes.selectInput : classes.selectInputRed}`}
                            id="damagedWindow"
                            onChange={handleChange}
                            name="damagedWindow">
                            <option selected disabled hidden value={values.damagedWindow}>Which window is damaged?</option>
                            <option value="Front windshield">Front windshield</option>
                            <option value="Driver side window">Driver side window</option>
                            <option value="Passenger side window">Passenger side window</option>
                            <option value="Real driver side window">Real driver side window</option>
                            <option value="Real passenger side window">Real passenger side window</option>
                            <option value="Real windshield">Real windshield</option>
                        </select>
                        <div className={classes.textDangerServices}>
                            {errors.damagedWindow}
                        </div>
                    </div>
                    <div className={classes.selectDiv}>
                        <select
                            className={`${errors.operateSafely===null? classes.selectInput : classes.selectInputRed}`}
                            id="operateSafely"
                            onChange={handleChange}
                            name="operateSafely">
                            <option selected disabled hidden value={values.operateSafely}>Can you operate your vehicle safely</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </select>
                        <div className={classes.textDangerServices}>
                            {errors.operateSafely}
                        </div>
                    </div>
                </div>
                <div className={classes.buttonsDiv}>
                    <button className={classes.leftBtn} disabled={!formIsValid} type={"submit"}>Submit Repair Request</button>
                    {/*<div className={classes.rightBtn}>Save as Draft</div>*/}
                </div>
            </form>
        </>
    )
}


export default AdditionalServicesPart2;