import React, { useState} from "react";
import classes from './PersonalDetails.module.css';
import Edit from '../../../assets/images/settings/Edit.png';
import Confirm from '../../../assets/images/settings/Confirm.png';
import useValidation from "../../../hooks/useValidation";
import useInput from "../../../hooks/useInput";
import axios from "axios";
import config from "../../../config";
import Input from "../../../UI/Input/Input";
import EyeImage from "../../../assets/images/EyeImage.png";
import ClosedEye from "../../../assets/images/ClosedEye.png";
import Close from "../../../assets/images/close.png";
import GreenConfirm from "../../../assets/images/settings/GreenConfirm.png";
import {setUserData} from "../../../Features/User/UserSlice";
import {toast} from "react-toastify";
import Loader from "../../../UI/Loader/Loader";
import {useDispatch} from "react-redux";



function PersonalDetails(props){

    const[showPersonalDetails, setShowPersonalDetails]=useState(false);
    const {isPhoneNumber, isEmail, isPassword, isNotEmpty}=useValidation();


    const showPersonalDetailsHandler=()=>{
        setShowPersonalDetails(!showPersonalDetails)
    }

    const {
        value: name,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName,
    } = useInput(isNotEmpty);
    const {
        value: surname,
        isValid: surnameIsValid,
        hasError: surnameHasError,
        valueChangeHandler: surnameChangeHandler,
        inputBlurHandler: surnameBlurHandler,
        reset: resetSurname,
    } = useInput(isNotEmpty);

    const {
        value: phoneNumber,
        isValid: phoneNumberIsValid,
        hasError: phoneNumberHasError,
        valueChangeHandler: phoneNumberChangeHandler,
        inputBlurHandler: phoneNumberBlurHandler,
        reset: resetPhoneNumber,
    } = useInput(isPhoneNumber);

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
        showPassFalse:showPassFalse,
        isTouched: passwordIsTouched
    } = useInput(isPassword);

    const {
        value: confirmPasswordValue,
        isValid: confirmPasswordIsValid,
        valueChangeHandler: confirmPasswordChangeHandler,
        inputBlurHandler: confirmPasswordBlurHandler,
        togglePassword: confirmPasswordShowHandler,
        passwordShown: confirmPasswordShow,
        reset: resetConfirmPassword,
        isTouched: confirmPasswordIsTouched
    } = useInput(isPassword);


    let hasError = false;
    let confirmPasswordMessage=null;
    if(password !== confirmPasswordValue){
        hasError = true;
        confirmPasswordMessage = "Passwords do not match"
    }

    let emailMessage =null;
    if(!email){
        emailMessage = "Email is required";
    }
    else if (!emailIsValid){
        emailMessage = "Invalid email";
    }

    // let formIsValid = false;
    // if (nameIsValid
    //     && surnameIsValid
    //     && phoneNumberIsValid
    //     && emailIsValid
    //     && passwordIsValid
    //     && confirmPasswordIsValid
    // ) {
    //     formIsValid = true;
    // }



    const submitHandler =  (event) => {
        // event.preventDefault();
        // console.log(formIsValid, "formIsValid")
        // if (!formIsValid) {
        //     return;
        // }
        console.log("dlkjfkldjfffffff");
        let formData = new FormData();
        formData.append('first_name', name);
        formData.append('last_name', surname);
        formData.append('email', email);
        formData.append('phone_number', phoneNumber);
        formData.append('password', password);
         props.onPostPersonalDetails(formData);
         showPersonalDetailsHandler();
         resetName();
         resetSurname();
          resetPhoneNumber();
          resetEmail();
          resetPassword();
          resetConfirmPassword();
         showPassFalse();
    };


    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }

    function closeAndResetPersonalDetails() {
        showPersonalDetailsHandler();
        resetName();
        resetSurname();
        resetPhoneNumber();
        resetEmail();
        resetPassword();
        resetConfirmPassword();
        showPassFalse()
        // setShowAddVehicle(!showAddVehicle);
        // resetLicensePlate();
        // resetStateOfProvince();
        // resetNickname();
        // resetCarmodel()
        // // setVerifyEmailError("")
    }

    return(
        <div className="settingsWhole">
            <div className="settingsTitle">
                <div className="titleDetails">Personal Details</div>
                {!showPersonalDetails?  <div className="edit" onClick={showPersonalDetailsHandler}>
                    <img src={Edit} className="editImg" alt=""/>Edit</div>
                : <div className={classes.titleRightPart}>
                        <div className={classes.saveBtn} onClick={submitHandler}>
                            <img src={Confirm} className="editImg" alt=""/>
                        {/*{formIsValid? <img src={GreenConfirm} className="editImg" alt=""/>:*/}
                        {/*<img src={Confirm} className="editImg" alt=""/>}*/}
                            Save</div>
                         <div className="edit" onClick={closeAndResetPersonalDetails}> <img src={Close} className="editImg" alt=""/>Close</div>
                    </div>
                }
            </div>
            {!showPersonalDetails ?     <div>
                <div className={classes.personalInfo}>
                    <div className={classes.personalLeft}>Name Surname</div>
                    <div className={classes.personalRight}>{`${props?.personalData?.first_name + " "+ props?.personalData?.last_name}`}</div>
                </div>
                <div className={classes.personalInfo}>
                    <div className={classes.personalLeft}>Mobile Number</div>
                    <div className={classes.personalRight}>{props?.personalData?.phone_number}</div>
                </div>
                <div className={classes.personalInfo}>
                    <div className={classes.personalLeft}>Email</div>
                    <div className={classes.personalRight}>{props?.personalData?.email}</div>
                </div>
                <div className={classes.personalInfo}>
                    <div className={classes.personalLeft}>Password</div>
                    <div className={classes.personalRight}>**************
                        {/*<span className={classes.change}>Change</span>*/}
                    </div>
                </div>
            </div>
            :
                <form className={classes.personalInfoFilling}>
                    <Input
                        hasError={nameHasError}
                        label="Name"
                        errorText="Please enter Name."
                        width ='660px'
                        input={{
                            value:name || props?.personalData?.first_name,
                            placeholder: "Name",
                            type: "text",
                            onChange: nameChangeHandler,
                            onBlur:nameBlurHandler,
                        }}
                    />
                    <Input
                        hasError={surnameHasError}
                        label="Surname"
                        errorText="Please enter Surname."
                        width ='660px'
                        input={{
                            value:surname || props?.personalData?.last_name,
                            placeholder: "Surname",
                            type: "text",
                            onChange: surnameChangeHandler,
                            onBlur:surnameBlurHandler,
                        }}
                    />
                    <Input
                        hasError={phoneNumberHasError}
                        label="Mobile Number"
                        errorText="Please enter Mobile Number."
                        width ='660px'
                        input={{
                            value: phoneNumber || props?.personalData?.phone_number,
                            placeholder: "Mobile Number",
                            type: "text",
                            onChange: phoneNumberChangeHandler,
                            onBlur: phoneNumberBlurHandler
                        }}
                    />
                    <Input
                        hasError={emailHasError}
                        label="Email"
                        errorText={emailMessage}
                        width ='660px'
                        input={{
                            value: email ||  props?.personalData?.email,
                            placeholder: "Email",
                            type: "email",
                            onChange: emailChangeHandler,
                            onBlur: emailBlurHandler
                        }}
                    />
                    <Input
                        hasError = {passwordHasError}
                        label="New Password "
                        errorText="Please enter password. (Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)"
                        width ='660px'
                        image ={passwordShow ? EyeImage : ClosedEye}
                        onClick={passwordShowHandler}
                        input={{
                            value: password,
                            placeholder: "Password",
                            type:passwordShow ? "text" : "password",
                            onChange: passwordChangeHandler,
                            onBlur: passwordBlurHandler,
                        }}
                    />
                    <Input
                        hasError = {hasError && confirmPasswordIsTouched}
                        label="Confirm New Password"
                        errorText={confirmPasswordMessage}
                        width ='660px'
                        image ={confirmPasswordShow ? EyeImage : ClosedEye}
                        onClick={confirmPasswordShowHandler}
                        input={{
                            value: confirmPasswordValue,
                            placeholder: "Confirm Password",
                            type: confirmPasswordShow ? "text" : "password",
                            onChange: confirmPasswordChangeHandler,
                            onBlur: confirmPasswordBlurHandler,
                            onKeyPress:handleKeyPress
                        }}
                    />
                </form>
              }
        </div>
    )
}


export default PersonalDetails;
