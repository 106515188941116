import React, {useState} from "react";
import Arrow from "../../assets/images/settings/Arrow.png";
import classes from './Reservations.module.css';
import NewHeader from "../../components/NewHeader/NewHeader";



function Reservations(){

    const [showReservations, setShowReservations] =useState(false);

    const reservationsHandler =()=>{
        setShowReservations(!showReservations)
    }


    return(
        <>
            <NewHeader/>
            <div className={classes.reservationsWhole}>
                <div className={classes.titleReservations} onClick={reservationsHandler}>My Reservations</div>
                {!showReservations ?  <div className={classes.reservationsText}>You don’t have reservations yet</div>
                :
                    <div className={classes.reservationsPart}>
                        <div className={classes.reserveCard}>
                            <div>
                                <div className={classes.reservedPlace}>Midtown Plaza</div>
                                <div className={classes.reservedAddress}>1360 Peachtree ST. NE, Atlanta, GA, 30309</div>
                            </div>
                            <div className={classes.arrangement}>
                                <div className={classes.arrivalLeaving}>
                                    <span className={classes.arrivalLeavingPart}>Arrive by</span>
                                    <span className={classes.arrivalLeavingDay}>Fri, Oct 28</span>12:15 pm
                                </div>
                                <div><img src={Arrow}/></div>
                                <div className={classes.arrivalLeaving}>
                                    <span className={classes.arrivalLeavingPart}>Leave before</span>
                                    <span className={classes.arrivalLeavingDay}>Fri, Oct 28</span>15:15 pm
                                </div>
                            </div>
                            <div className={classes.order}>
                                <div className={classes.total}>Order Total</div>
                                <div className={classes.total}>$19.80</div>
                            </div>
                        </div>
                        <div className={classes.reserveCard && classes.reserveCardClosed}>
                            <div>
                                <div className={classes.reservedPlace}>Midtown Plaza</div>
                                <div className={classes.reservedAddress}>1360 Peachtree ST. NE, Atlanta, GA, 30309</div>
                            </div>
                            <div className={classes.arrangement}>
                                <div className={classes.arrivalLeaving}>
                                    <span className={classes.arrivalLeavingPart}>Arrive by</span>
                                    <span className={classes.arrivalLeavingDay}>Fri, Oct 28</span>12:15 pm
                                </div>
                                <div><img src={Arrow}/></div>
                                <div className={classes.arrivalLeaving}>
                                    <span className={classes.arrivalLeavingPart}>Leave before</span>
                                    <span className={classes.arrivalLeavingDay}>Fri, Oct 28</span>15:15 pm
                                </div>
                            </div>
                            <div className={classes.order}>
                                <div className={classes.total}>Order Total</div>
                                <div className={classes.total}>$19.80</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}


export default Reservations;