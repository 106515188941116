import React from "react";
import Icon1 from '../../../assets/images/home/Icon1.png';
import Icon2 from '../../../assets/images/home/Icon2.png';
import Icon3 from '../../../assets/images/home/Icon3.png';
import classes from './HomeSecondPart.module.css';


function HomeSecondPart() {


    return(
        <div className="container">
            <div className={classes.part2Whole}>
                <div className="card">
                    <div className={classes.iconDiv}><img src={Icon1}/></div>
                     <div className="cardTitle">Fast 'N Convenient Parking</div>
                    <div className={classes.cardText}>
                    No more fumbling with wasteful paper tickets, just create a free online account, Park, Pay, then
                    Go! Its paperless, quick and easy.  You can even schedule parking in advance.
                    </div>
                </div>
                <div className="card">
                    <div className={classes.iconDiv}><img src={Icon2}/></div>
                    <div className="cardTitle">Parking Expiration Notification</div>
                    <div className={classes.cardText}>
                        A notification is sent when your parking expires, allowing you to top up. No need to leave
                        your event, use any mobile device to log in and renew your parking online from any location,
                        anytime</div>
                </div>
                <div  className="card">
                    <div className={classes.iconDiv}><img src={Icon3}/></div>
                    <div className="cardTitle">Additional Services</div>
                    <div className={classes.cardText}>
                        Our additional services were  created to help customers who experience unexpected issues
                        while parked. Services include repairs, battery jumping, Pal escorts for safety and more.
                    </div>
                </div>
            </div>
        </div>
    )
}


export default HomeSecondPart;

