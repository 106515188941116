import React, {useState} from "react";
import Edit from '../../../assets/images/settings/Edit.png';
import Letter from '../../../assets/images/settings/Letter.png';
import Message from '../../../assets/images/settings/Message.png';
import classes from './Notifications.module.css';
import Confirm from "../../../assets/images/settings/Confirm.png";
import Close from "../../../assets/images/close.png";


function Notifications(){

    const[showNotifications, setNotifications]=useState(false);
    const [emailNotify, setEmailNotify] = useState({
        sessionStartEmail: null,
        remainingTimeEmail:null,
        sessionEndEmail: null,
        receiptEmail:null,
        remindersEmail:null,
        sessionStart: null,
        remainingTime:null,
        sessionEnd: null,
        receipt:null,
        reminders:null,
    });



    const checkboxChecked = (e) => {
        console.log(e)
        setEmailNotify((prev) => ({
            ...prev,
            [e.target.name]: +e.target.checked,
        }));
    };

    console.log(emailNotify, "emailNotify")



    const showNotificationsHandler=()=>{
        setNotifications(!showNotifications)
    }

    const closeAndResetNotifications =()=>{
        showNotificationsHandler();
        //reset anel checkbox
    }




    // let postRegistration = async (values)=> {
    //     console.log(values , "values");
    //     try {
    //         let formData = new FormData();
    //         formData = {
    //             first_name: values.first_name,
    //             last_name :  values.last_name,
    //             email: values.email,
    //             password: values.password,
    //             password_confirmation: values.password_confirmation,
    //             phone_number: values.phone_number,
    //             role:values.role,
    //         }
    //         let response = await axios.post(`${config.baseUrl}api/register`, formData);
    //         console.log(response.data, 'registration response data');
    //         if(response.data.data==="success"){
    //             localStorage.setItem('email', values.email);
    //             await props.closeSignUpModal();
    //             await props.openVerifyEmailModal();
    //         }
    //     }
    //     catch (e) {
    //         setSignUpError("Something went wrong");
    //         console.log(e.response, 'registrationError');
    //     }
    // }
    //
    const saveData = async event => {
        //showNotificationsHandler()


        // await postRegistration();
        // await  resetNameSurname();
        // await resetPhoneNumber();
        // await  resetEmail();
        // await  resetPassword();
        // await  resetConfirmPassword();
        // await showPassFalse()
    };



    return(
        <div className="settingsWhole">
            <div className="settingsTitle">
                <div className="titleDetails">Notifications</div>
                {!showNotifications ? <div className="edit" onClick={showNotificationsHandler}>
                        <img src={Edit} className="editImg" alt=""/>Edit</div>
                    :
                    <div className={classes.titleRightPart}>
                        <button className={classes.saveBtn} onClick={saveData}>
                            <img src={Confirm} className="editImg" alt=""/>Save
                        </button>
                        <div className="edit"
                             onClick={closeAndResetNotifications}
                        >
                            <img src={Close} className="editImg" alt=""/>Close</div>
                    </div>
                }
            </div>
            <div className={classes.emailText}>
                <div className={classes.notificationsLeft}></div>
                <div className={classes.email}><img className={classes.imgStyle} src={Letter} alt=""/>Email</div>
                <div className={classes.email}><img className={classes.imgStyle} src={Message} alt=""/>Notify</div>
            </div>
            {!showNotifications ?
                <>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>Session start</div>
                        <div className={`${classes.notificationsMiddle} 
                            ${emailNotify.sessionStartEmail ? classes.green : "" }`}>
                            {emailNotify.sessionStartEmail?"On" :"Off"}</div>
                        <div className={`${classes.notificationsRight} 
                            ${emailNotify.sessionStart ? classes.green : "" }`}>
                            {emailNotify.sessionStart?"On" :"Off"}</div>
                    </div>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>15 minutes remaining</div>
                        <div className={`${classes.notificationsMiddle} 
                            ${emailNotify.remainingTimeEmail ? classes.green : "" }`}>{emailNotify.remainingTimeEmail?"On" :"Off"}</div>
                        <div className={`${classes.notificationsRight} 
                            ${emailNotify.remainingTime ? classes.green : "" }`}>
                            {emailNotify.remainingTime?"On" :"Off"}</div>
                    </div>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>Session end</div>
                        <div className={`${classes.notificationsMiddle} 
                            ${emailNotify.sessionEndEmail ? classes.green : "" }`}>{emailNotify.sessionEndEmail?"On" :"Off"}</div>
                        <div className={`${classes.notificationsRight} 
                            ${emailNotify.sessionEnd ? classes.green : "" }`}>
                            {emailNotify.sessionEnd?"On" :"Off"}</div>
                    </div>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>Receipt</div>
                        <div div className={`${classes.notificationsMiddle} 
                            ${emailNotify.receiptEmail ? classes.green : "" }`}>{emailNotify.receiptEmail?"On" :"Off"}</div>
                        <div className={`${classes.notificationsRight} 
                            ${emailNotify.receipt? classes.green : "" }`}>
                            {emailNotify.receipt?"On" :"Off"}</div>
                    </div>
                </>
            :
                <>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>Session start</div>
                        <div className={classes.notificationsMiddle}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="sessionStartEmail"
                                onChange={checkboxChecked}
                                checked={emailNotify.sessionStartEmail}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.sessionStartEmail?"On" :"Off"}</label>
                        </div>
                        <div className={classes.notificationsRight}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="sessionStart"
                                onChange={checkboxChecked}
                                checked={emailNotify.sessionStart}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.sessionStart?"On" :"Off"}</label>
                        </div>
                    </div>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>15 minutes remaining</div>
                        <div className={classes.notificationsMiddle}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="remainingTimeEmail"
                                onChange={checkboxChecked}
                                checked={emailNotify.remainingTimeEmail}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.remainingTimeEmail?"On" :"Off"}</label>
                        </div>
                        <div className={classes.notificationsRight}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="remainingTime"
                                onChange={checkboxChecked}
                                checked={emailNotify.remainingTime}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.remainingTime?"On" :"Off"}</label>
                        </div>
                    </div>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>Session end</div>
                        <div className={classes.notificationsMiddle}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="sessionEndEmail"
                                onChange={checkboxChecked}
                                checked={emailNotify.sessionEndEmail}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.sessionEndEmail?"On" :"Off"}</label>
                        </div>
                        <div className={classes.notificationsRight}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="sessionEnd"
                                onChange={checkboxChecked}
                                checked={emailNotify.sessionEnd}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.sessionEnd?"On" :"Off"}</label>
                        </div>
                    </div>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>Receipt</div>
                        <div className={classes.notificationsMiddle}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="receiptEmail"
                                onChange={checkboxChecked}
                                checked={emailNotify.receiptEmail}
                                />
                            <label className={classes.checkBoxLabel}>{emailNotify.receiptEmail?"On" :"Off"}</label>
                        </div>
                        <div className={classes.notificationsRight}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="receipt"
                                onChange={checkboxChecked}
                                checked={emailNotify.receipt}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.receipt?"On" :"Off"}</label>
                        </div>
                    </div>
                    <div className={classes.notificationDiv}>
                        <div className={classes.notificationsLeft}>Receive reminders after midnight</div>
                        <div className={classes.notificationsMiddle}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="remindersEmail"
                                onChange={checkboxChecked}
                                checked={emailNotify.remindersEmail}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.remindersEmail?"On" :"Off"}</label>
                        </div>
                        <div className={classes.notificationsRight}>
                            <input
                                className={classes.checkboxStyle}
                                type="checkbox"
                                name="reminders"
                                onChange={checkboxChecked}
                                checked={emailNotify.reminders}
                            />
                            <label className={classes.checkBoxLabel}>{emailNotify.reminders?"On" :"Off"}</label>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}


export default Notifications;
