import React from "react";
import 'react-toastify/dist/ReactToastify.css'
import classes from './GeneralQuestions.module.css';
import Background3 from "../../../assets/images/home/Background3.png";
import ContactUsForm from "../ContactUsForm/ContactUsForm";


function GeneralQuestions() {


    return(
        <div className={classes.general}>
            <div className="container">
                <div className={classes.generalWhole}>
                    <div className={classes.generalLeft}>
                        <div className={classes.generalTitle}>WHO WE ARE</div>
                        <div className={classes.generalSubtitle}>More Than a Parking Service, We're Your Parkin' Pal</div>
                        <div className={classes.generalText}>Family owned and operated, Parkin’ Pals is a community
                            focused company based in Cincinnati, Ohio. We take pride in the ability to oversee and maintain
                            safe and fast parking systems that are easy for parking customers and partners. We strive to
                            give our customers peace of mind when parking with us. That’s why when you park in one of our
                            locations, you have access to services beyond parking. It doesn’t matter if it’s a flat tire
                            or a dead battery you can always count on your Pals to be there for you. Our mission is to
                            help build the communities we’re part of and that’s why we have partnered with several local
                            charities. We take pride in our ability to give back and help cultivate a safer Cincinnati,
                            by providing services that improve safety with every partnership we develop in the
                            community.</div>
                    </div>
                    <div className={classes.generalRight}>
                        <div className={classes.generalTitle}>GENERAL QUESTIONS</div>
                        <div className={classes.generalQuestion}>When are Parkin' Pal attendants available?</div>
                        <div className={classes.generalQuestion}>Who do I contact in an emergency?</div>
                        <div className={classes.generalQuestion}>Do I have to create an account to park?</div>
                        <div className={classes.generalQuestion}>Do I have to create an account to park?</div>
                    </div>
                </div>
            </div>
            <div className={classes.backgroundStyleContact} style={{ 'background-image': `url(${Background3})`}}>
                <div className="container">
                    <div className={classes.generalTitle}>CONTACT US</div>
                </div>
                <ContactUsForm/>
            </div>
        </div>


    )
}

export default GeneralQuestions;
