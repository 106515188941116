import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config";
import Modal from 'react-modal';
import NewInput from "../../UI/NewInput/NewInput";
import useInput from "../../hooks/useInput";
import useValidation from "../../hooks/useValidation";
import classes from './ForgetPasswordModal.module.css';



function ForgetPasswordModal(props){

    const customStyles = {
        content: {
            maxWidth: '697px',
            overflowY: 'hidden',
            padding:'0',
            width: '100%',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex:'10000'
        },
    };



    const {isEmail}=useValidation();
    const [forgetPasswordError, setForgetPasswordError] = useState(null);

    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
        isTouched:emailIsTouched,
    } = useInput(isEmail);

    useEffect(() => {
        if(emailIsTouched){
            setForgetPasswordError("")
        }
    },[emailIsTouched]);


    let emailMessage =null;
    if(!email){
        emailMessage = "Email is required";
    }
    else if (!emailIsValid){
        emailMessage = "Invalid email";
    }


    let formIsValid = false;
    if (emailIsValid) {
        formIsValid = true;
    }

    function closeAndResetForgetPasswordModal (){
        resetEmail();
        props.closeForgetPasswordModal();
        setForgetPasswordError("");
    }

    function openSignUpCloseForgot (){
        props.closeForgetPasswordModal();
        props.openSignUpModal();
        setForgetPasswordError("");
        resetEmail();
    }


    const postForgetPassword = async (email) => {
        let formData = new FormData();
        formData = {
            email: email,
        }
        try {
            console.log(email, "email")
            let response = await axios.post(`${config.baseUrl}api/forgot/password`, formData);
            console.log(response.data);
            if(response.data.success===true){
                props.closeForgetPasswordModal();
                props.openPasswordResetModal();
                props.handleCallBack(email);
            }
        } catch (error) {
            console.log(error, "forgetPasswordModalError")
            setForgetPasswordError("Something went wrong");
        }
    }

    const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        await postForgetPassword(email);
        await resetEmail();
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }


    return(
           <Modal
                isOpen={props.forgetPasswordIsOpen}
                onRequestClose={closeAndResetForgetPasswordModal}
                // onAfterOpen={props.closeLoginModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div  className={classes.modalWhole}>
                    <div className={classes.modalTitle}>Forgot  Your Password</div>
                    <div className={classes.modalSubTitle}>Please enter the email address your like your password reset information sent to </div>
                    <form onSubmit={submitHandler}>
                        <NewInput
                            loginError={forgetPasswordError}
                            hasError = {emailHasError}
                            errorText={emailMessage}
                            label ={"Email"}
                            input={{
                                value: email,
                                placeholder: "Your Email",
                                type: "email",
                                onChange: emailChangeHandler,
                                onBlur: emailBlurHandler,
                                onKeyPress:handleKeyPress
                            }}
                        />
                        <div className={classes.btnErrorDiv}>
                            <button className={classes.resetPasswordBtn} disabled={!formIsValid} type={"submit"}>Reset Password</button>
                            {forgetPasswordError && <div className={classes.forgetPasswordError}>{forgetPasswordError}</div>}
                        </div>
                    </form>
                    <div className={classes.backToSign} onClick={()=>openSignUpCloseForgot()}>Back to sign in</div>
                </div>
            </Modal>
    )
}


export default ForgetPasswordModal;
