import React from "react";

const MyMarker = ({ icon, tooltip, $hover }) => {
    const handleClick = () => {
        console.log(`You clicked on ${tooltip}`);
    };

    return (
        <div className={$hover ? "circle hover" : "circle"} onClick={handleClick}>
      <span className="circleText" title={tooltip}>
          <img src={icon.url}/>
      </span>
        </div>
    );
};

export default MyMarker;