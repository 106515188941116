import React, {useState, useEffect} from "react";
import axios from "axios";
import moment from "moment";
import config from "../../config";
import EvCharge from '../../assets/images/profile/EvCharge.png';
import Filter from '../../assets/images/profile/Filter.png';
import Ticket from '../../assets/images/profile/Ticket.png';
import NewHeader from "../../components/NewHeader/NewHeader";
import SimpleMap from "../../components/MapElement/MapElement";
import Map from '../../assets/images/profile/Map.png';
import ParkingIcon1 from '../../assets/images/profile/ParkingIcon1.png';
import ParkingIcon2 from '../../assets/images/profile/ParkingIcon2.png';
import ParkingIcon3 from '../../assets/images/profile/ParkingIcon3.png';
import ParkingIcon4 from '../../assets/images/profile/ParkingIcon4.png';
import LeftArrow from '../../assets/images/profile/LeftArrow.png';
import RightArrow from '../../assets/images/profile/RightArrow.png';
import List from '../../assets/images/profile/List.png';
import classes from './MyProfile.module.css';
import {useDispatch, useSelector} from "react-redux";
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import MyProfileSwiper from "../../components/MyProfileSwiper/MyProfileSwiper";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "../../UI/Loader/Loader";
import {setParkingZoneData} from "../../Features/ParkingZone/ParkingZoneSlice";


function MyProfile() {

    const navigate=useNavigate();
    const dispatch=useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showBlock, setShowBlock]=useState("events");
    const [showParkingDetails, setShowParkingDetails]=useState(false);
    const [events, setEvents]=useState([]);
    const [parkings, setParkings]=useState([]);
    const parkingZone = useSelector((state)=>state?.parkingZone?.data);
    console.log(parkingZone, "parkingZone from redux")
    const [currentItemIndex, setCurrentItemIndex] = useState();


    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    useEffect(() => {
        getEventsData();
        getParkingsData();
    }, []);
console.log(currentItemIndex, "currentItemIndex")

    const handleChangeBlock=(data)=>{
        setShowBlock(data);
    }

    console.log(parkings, "parkings");
    console.log(parkingZone, "parkingZone")

    let getEventsData = async () => {
        let token= sessionStorage.getItem('token') || localStorage.getItem('token')
        try {
            let response = await axios.get(`${config.baseUrl}api/event`,{
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            });
            console.log(response.data.data, "events response data")
            setEvents(response.data.data);
        } catch (error) {
            console.log(events, "event error")
            console.log(error, 'event error');
            console.log(error.response, 'event error response');
        }
    }


    let getParkingsData = async () => {
        let token= sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/customer/parking`, {
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            dispatch(setParkingZoneData(response.data.data));
            setParkings(response.data.data);
            console.log(response.data.data, "getParkingsData")
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


    let getParkingZoneData = async (id) => {
        // if(currentItemIndex !==0 && currentItemIndex !==parkingZone.length-1) {
        // }
            let token = sessionStorage.getItem('token');
            setIsLoading(true);
            try {
                let response = await axios.get(`${config.baseUrl}api/customer/parking/zone?id=${id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                console.log(response.data.data, "zone data with id");
                setIsLoading(false);
                setShowParkingDetails(true);
                console.log(response.data.data, "getParkingZoneData IDDD");
                dispatch(setParkingZoneData(response.data.data));
            } catch (error) {
                setIsLoading(false);
                console.log(error, 'parking zone  error');
                toast.error(
                    error.response?.data.message || "Something went wrong"
                )
            }
    }


    let getPreview = () => {
        console.log(currentItemIndex, "currentItem Index");
        if(currentItemIndex !==0) {
            let nextIndex = currentItemIndex -1;
            setCurrentItemIndex(nextIndex);
            getParkingZoneData(parkings[nextIndex].id);
        }
    };

    let getNext = () => {
        console.log(currentItemIndex, "currentItem Index");
        if(currentItemIndex !==parkings.length-1) {
            let nextIndex = currentItemIndex +1;
            setCurrentItemIndex(nextIndex);
            getParkingZoneData(parkings[nextIndex].id);
        }
    };

    let getParkingDetails =(a)=> {
        let elementIndex = parkings?.findIndex((elem) => elem.id === a);
        setCurrentItemIndex(elementIndex);
        getParkingZoneData(a)
    }

    const renderEvents = events?.map((item, index)=>(
        <div className={classes.eventsParkingsAll}>
            <div className={classes.eventsParkingsLeft}>
                <img className={classes.eventParkingImage} src={item['image-link']} alt=""/>
            </div>
            <div className={classes.eventsParkingsRight}>
                <div className={classes.eventsTitle}>{item.title}</div>
                <div className={classes.eventsPlace}>{item.description}</div>
                <div className={classes.eventsStartDate}> {moment(item.date_start).format('YYYY-MM-DD')}
                      <span className={classes.eventsEndDate}> | {moment(item.date_end).format('YYYY-MM-DD')}</span>
                </div>
                <div className={classes.btn}
                     onClick={()=>openInNewTab(`${item.link}`)}>
                    <img className={classes.ticket} src={Ticket}/>Events</div>
            </div>
        </div>
    ))


    const renderParkings = parkings?.map((item,index)=>(
        <div className={classes.eventsParkingsAll} key={item.id}>
            <div className={classes.eventsParkingsLeft}>
                <img className={classes.eventParkingImage} src={item?.media?.[0]?.original_url} alt=""/>
            </div>
            <div className={classes.eventsParkingsRight}>
                <div className={classes.location}><img className={classes.map} src={Map} alt=""/>{item.address}</div>
                <div className={classes.namePrice}>
                    <div className={classes.name}>{item.name}</div>
                    <div className={classes.price}>{item.regular_price} USD p/h</div>
                </div>
                <div className={classes.parkingsIcons}>
                    <img className={classes.parkingIcon} src={ParkingIcon1} alt=""/>
                    <img className={classes.parkingIcon} src={ParkingIcon2} alt=""/>
                    <img className={classes.parkingIcon} src={ParkingIcon3} alt=""/>
                    <img className={classes.parkingIcon} src={ParkingIcon4} alt=""/>
                </div>
                <div className={classes.parkingBtns}>
                    <div className={classes.detailsBtn}
                         onClick={()=>getParkingDetails(item.id)}>Details</div>
                    <div className={classes.reserveBtn}
                         onClick={()=>{navigate(`/reserve-parking/${item.id}`);
                      getParkingZoneData(item.id)}}>Reserve</div>
                </div>
            </div>
        </div>
    ))


    if (isLoading) return <Loader/>;


    return (
        <>
            <NewHeader/>
            <div className={classes.profileWhole}>
                {!showParkingDetails ? <div className={classes.profileLeft}>
                    <div className={classes.apply}>
                        <img className={classes.filter} src={Filter}/>Apply Filters</div>
                    <div className={classes.filtersBelow}>
                        <div className={`${classes.events} ${showBlock === "events" ? classes.greyBackground : "" }`}
                             onClick={()=>{handleChangeBlock("events");
                             setCurrentItemIndex(null)}}>Events</div>
                        <div className={`${classes.parkings} ${showBlock === "parkings" ? classes.greyBackground : "" }`}
                             onClick={()=>{handleChangeBlock("parkings");
                                 setCurrentItemIndex(null)}}>Parkings</div>
                    </div>
                    <div className={classes.renderEvents}>
                        {showBlock === "events" ? renderEvents: renderParkings}
                    </div>
                </div>
                :
                    <div className={classes.parkingZone}>
                        <div className={classes.apply}>
                            <div className={classes.iconButton} onClick={()=>{setShowParkingDetails(false);
                                setCurrentItemIndex(null)}}>
                                <img src={List} className={classes.icon} alt=""/>Back to list</div>
                            <div
                                 className={currentItemIndex === 0 ? classes.iconButtonNotActive:classes.iconButton}
                                 onClick={() => getPreview()}>
                                <img src={LeftArrow} className={classes.icon} alt=""/>Preview</div>
                            <div
                                className={currentItemIndex === parkings?.length-1 ? classes.iconButtonNotActive:classes.iconButton}
                                onClick={() => getNext()}
                            >Next
                                <img src={RightArrow} className={classes.icon_left} alt=""/></div>
                            </div>
                        <div>
                            <div className={classes.zoneAddress}>{parkingZone?.address}</div>
                            <MyProfileSwiper parkingZone={parkingZone}/>
                            {/*<img className={classes.parkingZoneImg} src={parkingZone?.media[0]?.original_url} alt=""/>*/}
                            <div className={classes.title}>Amenities</div>
                            <div className={classes.evCharge}>
                                <img className={classes.evChargeImg} src={EvCharge}/>EV Charge
                            <div className={classes.respondEvCharge}> - {parkingZone?.ev_charge}</div></div>
                            <div className={classes.title}>Location Details
                                <div className={classes.locationDetails}>{parkingZone?.location_details}</div>
                            </div>
                            <div className={classes.title}>Hours of Operation
                                <div className={classes.hoursData}>
                                    {parkingZone?.work_days?.map((item,index)=>(
                                        <span key={index}>{moment.weekdays(item.day_number)} - {item.open_time} - {item.close_time}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className={classes.buttonBox}><SubmitButton
                                OnClick={()=>navigate(`/reserve-parking/${parkingZone.id}`)}>Reserve Parking</SubmitButton></div>
                        </div>
                    </div>
            }
                <div className={classes.profileRight}>
                    <SimpleMap events={events} parkings={parkings} showBlock={showBlock}/>
                </div>
            </div>
        </>
    )
}

export default MyProfile;