import React, {useEffect, useState} from "react";
import classes from './Settings.module.css';
import NewHeader from "../../components/NewHeader/NewHeader";
import Vehicles from "../../components/SettingsComponents/Vehicles/Vehicles";
import Notifications from "../../components/SettingsComponents/Notifications/Notifications";
import PersonalDetails from "../../components/SettingsComponents/PersonalDetails/PersonalDetails";
import PaymentMethods from "../../components/SettingsComponents/PaymentMethods/PaymentMethods";
import axios from "axios";
import config from "../../config";
import {toast} from "react-toastify";
import Loader from "../../UI/Loader/Loader";
import {setUserData} from "../../Features/User/UserSlice";
import {useDispatch} from "react-redux";




function Settings(){

    const [isLoading, setIsLoading] = useState(false);
    const [vehicleData, setVehicleData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [personalData, setPersonalData]=useState();
    const [notificationsData, setNotificationsData]=useState([])
    const dispatch=useDispatch();


    useEffect(()=>{
        getPersonalData();
        getVehiclesAll();
        getPaymentsAll();
        getNotificationsAll()
    },[]);



    let getPersonalData = async () => {
        let token= sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/user`, {
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setPersonalData(response.data)
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


    let getVehiclesAll = async () => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/vehicle`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setVehicleData(response.data.data);
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


    let getPaymentsAll = async () => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/payment-method`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setPaymentData(response.data.data)
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    let getNotificationsAll = async () => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.get(`${config.baseUrl}api/customer/notify/setting`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setIsLoading(false);
            setNotificationsData(response.data.data)
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    console.log(notificationsData, "notificationsData")

    let addVehicle = async (form) => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.post(`${config.baseUrl}api/vehicle/create`, form, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            toast.success(response.data.message);
            let vehicleDataCopy = [...vehicleData];
            vehicleDataCopy.push(response.data.data);
            setVehicleData(vehicleDataCopy);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error")
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


    let addCard = async (form) => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.post(`${config.baseUrl}api/payment-method/create`, form, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            console.log(response.data, 22222222222)
            toast.success(response.data.message);
            let paymentDataCopy = [...paymentData];
            paymentDataCopy.push(response.data.data);
            setPaymentData(paymentDataCopy);
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    let postPersonalDetails  = async (form) => {
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        try {
            let response = await axios.post(`${config.baseUrl}api/personal/details/update`, form, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            dispatch(setUserData(response.data.data));
            setPersonalData(response.data.data)
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error")
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }

    if (isLoading) return <Loader/>;


    return(
        <>
            <NewHeader/>
            <div className="container">
                <div className={classes.settingsWhole}>
                    <div className={classes.titleSettings}>Settings</div>
                    <div className={classes.settingsRow}>
                        <PersonalDetails personalData={personalData} onPostPersonalDetails={postPersonalDetails}/>
                        <Vehicles onAddVehicle={addVehicle} vehicleData={vehicleData}/>
                    </div>
                    <div className={classes.settingsRow}>
                        <Notifications/>
                        <PaymentMethods onAddCard={addCard} paymentData={paymentData}/>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Settings;