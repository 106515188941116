import React from "react";
import { Swiper,  SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, Navigation }  from 'swiper';
import 'swiper/css';
import 'swiper/css/keyboard';
import "swiper/css/pagination";
import "swiper/css/navigation";
import classes from "../MyProfileSwiper/MyProfileSwiper.module.css";




export default (props) => {



    const renderProfileImages=props?.parkingZone?.media?.map((item, index)=>(
        <SwiperSlide className={classes.swiperDiv} key={index}>
            <div className={classes.swiperDiv}>
                <img className={classes.parkingZoneImg} src={item?.original_url}/>
            </div>
        </SwiperSlide>
    ))

    return (
        <Swiper
            className="max_width"
            slidesPerView={1}
            initialSlide={0}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            keyboard={{ enabled: true }}
            modules={[Keyboard, Pagination, Navigation]}
            pagination={{
                clickable: true,
            }}
            navigation={true}
        >
            {renderProfileImages}
        </Swiper>
    );
};