import React from "react";
import classes from './AdditionalServicesPart3.module.css';
import Background2 from "../../../assets/images/additionalServices/Background2.png";
import Call from '../../../assets/images/additionalServices/Call.png';



function AdditionalServicesPart3(props) {

        const phoneNumber = '+1234567890';
        const handleCallClick = () => {
            window.open(`tel:${phoneNumber}`);
        };

    return(
        <>
            <div className={classes.backgroundStyle} style={{ 'background-image': `url(${Background2})`}}>
                <div className={classes.titlePart3}>Become a Parkin' Pal Member And Get Exclusive Specials</div>
                <div className={classes.subTitlePart3}>Becoming a Parkin’ Pal Member is FREE and easy. All you need to do
                    is create an account with us and receive perks like</div>
                <button className={classes.bluePartBtn} onClick={props.OnOpenSignUpModal}>Create an account today</button>
            </div>
            <button className={classes.callBtn} onClick={handleCallClick}><img src={Call}/>Click to call us now!</button>
            {/*<a href="tel:+1234567890">  Click to Call Us Now!</a>*/}
        </>
    )
}

export default AdditionalServicesPart3;