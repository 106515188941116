import {configureStore} from "@reduxjs/toolkit";
import userSlice from "../Features/User/UserSlice";
import parkingZoneSlice from "../Features/ParkingZone/ParkingZoneSlice";



export const store = configureStore({
    reducer:{
        user: userSlice,
        parkingZone:parkingZoneSlice
    }
})