import React, {useEffect, useState} from "react";
import Input from "../../../UI/Input/Input";
import useInput from "../../../hooks/useInput";
import useValidation from "../../../hooks/useValidation";
import axios from "axios";
import config from "../../../config";
import "react-toastify/dist/ReactToastify.minimal.css";
import 'react-toastify/dist/ReactToastify.css';
// import 'react-toastify/dist/ReactToastify.min.css';
import {toast} from "react-toastify";
import Loader from "../../../UI/Loader/Loader";
import classes from './ContactUsForm.module.css';
import {useLocation} from "react-router-dom";



function ContactUsForm(){

    const [isLoading, setIsLoading] = useState(false);
    const routePath = useLocation();

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        onTop();
    }, [routePath]);

    const {isName,  isNotEmpty, isEmail}=useValidation();


    const {
        value: fullname,
        isValid: fullnameIsValid,
        hasError: fullnameHasError,
        valueChangeHandler: fullnameChangeHandler,
        inputBlurHandler: fullnameBlurHandler,
        reset: resetFullname,
    } = useInput(isName);


    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);


    // const {
    //     value: subject,
    //     isValid: subjectIsValid,
    //     hasError: subjectHasError,
    //     valueChangeHandler: subjectChangeHandler,
    //     inputBlurHandler: subjectBlurHandler,
    //     reset: resetSubject,
    // } = useInput(isNotEmpty);

    const{
        value:textValue,
        isValid:textValueIsValid,
        hasError:textValueHasError,
        valueChangeHandler:textValueChangeHandler,
        inputBlurHandler:textValueBlurHandler,
        reset:resetTextArea,
    } =useInput(isNotEmpty)


    let emailMessage =null;
    if(!email){
        emailMessage = "Email is required";
    }
    else if (!emailIsValid){
        emailMessage = "Invalid email";
    }



    let formIsValid = false;
    if (fullnameIsValid
        && emailIsValid
        && textValueIsValid
    ) {
        formIsValid = true;
    }

    let sendContactForm = async ()=>{
        let token = sessionStorage.getItem('token');
        setIsLoading(true);
        let formData = new FormData();
        formData.append('name', fullname);
        formData.append('email', email);
        formData.append('description', textValue);
        try {
            let response = await axios.post(`${config.baseUrl}api/contact/create`, formData, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            setIsLoading(false);
            console.log(response.data, "needed message")
            if(response.data.data==="Success"){
                toast.success("Form is sent successfully!");
            }
            console.log(response.data, "contact us")
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong"
            )
        }
    }


        const submitHandler = async event => {
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        sendContactForm();
        console.log("true")
        resetFullname();
        resetEmail();
        resetTextArea()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            submitHandler()
        }
    }


    if (isLoading) return <Loader/>;

    return(
        <div className={classes.contactForm}>
            <div className={classes.contactSubtitle}>We are trusted by clients all around Cincinnati</div>
            <div className={classes.contactTitle}>Whether your looking for somewhere to park short-term, you need a
                monthly parking solution or your a parking lot owner looking for a management team to partner with,
                Parkin’ Pals has solutions for you. We’re more than just a parking service, we’re your Parkin’ Pals.
            </div>
            <form
                className={classes.formHome}
                onSubmit={submitHandler}
            >
                <div className={classes.contactFormRow}>
                    <Input
                        hasError={fullnameHasError}
                        errorText="Please enter Full Name."
                        width ='505px'
                        input={{
                            value: fullname,
                            placeholder: "Full Name",
                            type: "text",
                            onChange: fullnameChangeHandler,
                            onBlur: fullnameBlurHandler
                        }}
                    />
                    <Input
                        hasError={emailHasError}
                        errorText={emailMessage}
                        width ='505px'
                        input={{
                            value: email,
                            placeholder: "Your Email Address",
                            type: "email",
                            onChange: emailChangeHandler,
                            onBlur: emailBlurHandler
                        }}
                    />
                </div>
                {/*<Input*/}
                {/*    hasError={subjectHasError}*/}
                {/*    errorText="Please enter subject."*/}
                {/*    width ='1026px'*/}
                {/*    input={{*/}
                {/*        value: subject,*/}
                {/*        placeholder: "Subject",*/}
                {/*        type: "text",*/}
                {/*        onChange: subjectChangeHandler,*/}
                {/*        onBlur: subjectBlurHandler*/}
                {/*    }}*/}
                {/*/>*/}
                <div className={classes.textAreaHomePage}>
                <textarea  className={`${textValueHasError? classes.invalid : ""}`} rows="5"
                           value={textValue}
                           onChange={textValueChangeHandler}
                           onKeyPress={handleKeyPress}
                           onBlur={textValueBlurHandler}
                           placeholder='Message'/>
                    {textValueHasError &&  <div className={classes.textDanger}>Please fill in the textarea</div>}
                </div>
                    <div className={classes.btnDiv}>
                    <button className={classes.sendBtn} disabled={!formIsValid} type={"submit"}>Send</button>
                </div>
            </form>
        </div>
    )
}

export default ContactUsForm;
