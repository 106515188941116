import React from "react";
import Phone from '../../../assets/images/home/Phone.png';
import classes from './HomeThirdPart.module.css'



function HomeThirdPart() {
    return(
        <div className={classes.thirdPartWhole}>
            <div className={classes.thirdPart}>
                <div className={classes.phoneImgDiv}>
                    <img src={Phone}/>
                </div>
                <div className={classes.thirdPartRight}>
                    <div className={classes.thirdPartTitle}>FEATURES</div>
                    <div className={classes.thirdPartText}>Account access for easy management</div>
                    <div className={classes.thirdPartText}>Reserve parking in advance</div>
                    <div className={classes.thirdPartText}>Monthly Parking Option</div>
                    <div className={classes.thirdPartText}>Earn rewards and become a V.I.Pal</div>
                    <div className={classes.thirdPartText}>Multiple Locations</div>
                    <div className={classes.thirdPartText}>Live Access to Parking Availability​</div>
                </div>
            </div>
        </div>
    )
}


export default HomeThirdPart;
