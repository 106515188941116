import React from "react";
import success from '../../assets/images/success.png';
import classes from './SuccessReserved.module.css';
import Footer from "../../components/Footer/Footer";
import NewHeader from "../../components/NewHeader/NewHeader";



function SuccessReserved (){
    return(
        <div className={classes.successWhole}>
            <div>
                <NewHeader/>
                <div className={classes.imgStyle}><img src={success} alt={""}/></div>
                <div className={classes.title}>Congratulations!</div>
                <div className={classes.subtitle}>Your Parking Place Was Reserved Successfully !</div>
            </div>
            <Footer/>
        </div>

    )
}

export default SuccessReserved;