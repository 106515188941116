import React, {useEffect} from "react";
import classes from './AdditionalServices.module.css';
import Header from "../../components/Header/Header";
import Background1 from "../../assets/images/additionalServices/Background1.png";
import AdditionalServicesPart1 from "../../components/AdditionalServices/AdditionalServicesPart1/AdditionalServicesPart1";
import AdditionalServicesPart3
    from "../../components/AdditionalServices/AdditionalServicesPart3/AdditionalServicesPart3";
import Footer from "../../components/Footer/Footer";
import AdditionalServicesPart2
    from "../../components/AdditionalServices/AdditionalServicesPart2/AdditionalServicesPart2";
import {useLocation} from "react-router-dom";


function AdditionalServices(props) {
    const routePath = useLocation();

    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        onTop();
    }, [routePath]);

    const open=()=>{
        props.openSignUpModal();
        console.log("hiii")
    }

    return(
        <>
            <Header
                loginModalIsOpen={props.loginModalIsOpen}
                openLoginModal={props.openLoginModal}
                closeLoginModal={props.closeLoginModal}
                signUpModalIsOpen={props.signUpModalIsOpen}
                openSignUpModal={props.openSignUpModal}
                closeSignUpModal={props.closeSignUpModal}
            />
            <div className={classes.backgroundStyle} style={{ 'background-image': `url(${Background1})`}}>
                <div className={classes.titleAdditionalPage}>Glass Repair | Tire Services | Battery Jumping | Gas
                    ServicesPrepared for the unexpected, Parkin’ Pals are here for all your parking needs</div>
            </div>
            <AdditionalServicesPart1/>
            <AdditionalServicesPart2/>
            <AdditionalServicesPart3 OnOpenSignUpModal={props.openSignUpModal}/>
            {/*<SignUpModal/>*/}
            <Footer/>
        </>
    )
}


export default AdditionalServices;